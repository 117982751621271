import { SchemaTypes } from "components/HeaderSchema";
import { hashText } from "utils/gmtHash";

export type InfographicFaqCmsProps = {
  reference_6: InfographicFaqCmsDataProps[];
};

export type InfographicFaqCmsDataProps = {
  uid: string;
  display_title: string;
  subtitle: string;
  infographic_file: {
    url: string;
    description: string;
  };
  alignment: InfographicFaqAlignment;
  faq: FaqData[];
  utm_content: UTMFaqCmsData[];
};

export type UTMFaqCmsData = {
  utm: string;
  infographic_file: {
    url: string;
    alt: string;
    description: string;
  };
  display_title: string;
  subtitle: string;
  faq: FaqData[];
};

export type UTMFaqData = {
  utm: string;
  infographicFile: string;
  infographicAlt: string;
  displayTitle: string;
  subtitle: string;
  faq: FaqData[];
};

export type FaqData = {
  question: string;
  answer: string;
};

export type InfographicFaqDataProps = {
  uid: string;
  displayTitle: string;
  subtitle: string;
  infographicFile: string;
  infographicAlt: string;
  alignment: InfographicFaqAlignment;
  faq: FaqData[];
  utmContent: UTMFaqData[];
  faq_schema: SchemaTypes[];
};

export enum InfographicFaqAlignment {
  RIGHT = "right",
  LEFT = "left",
}

export type FaqBlockProps = {
  displayTitle: string;
  subtitle: string;
  faq: FaqData[];
};

export type InfographicBlockProps = {
  infographicFile: string;
  infographicAlt: string;
};

export type InfographicFaqData = {
  uid: string;
  displayTitle: string;
  subtitle: string;
  infographicFile: string;
  infographicAlt: string;
  alignment: InfographicFaqAlignment;
  faq: FaqData[];
};

export const SESSION_STORAGE_UTM_KEY = hashText("UTM_CONTENT");
