import isArray from "lodash/isArray";
import { createContext } from "react";
import { Language } from "../types";

export interface I18nContextData {
  language: Language;
  defaultLanguage: Language;
  supportedLanguages: Record<string, Language>;
  countryStackName: string;
  countryCode: string;
  changeLanguage: (languageCode: string) => void;
  siteSettings: any;
  seo: any;
  componentConfigs?: any;
  breadcrumbs?: any;
}

const I18nContext = createContext({
  language: {} as any,
  defaultLanguage: {} as any,
  supportedLanguages: {},
  countryStackName: "",
  countryCode: "",
  changeLanguage: () => {},
  siteSettings: {} as any,
  seo: {} as any,
  componentConfigs: {} as any,
  breadcrumbs: {} as any,
} as I18nContextData);

export const getSEOFromComponents = (components: any) => {
  if (isArray(components?.layout)) {
    for (const component of components.layout) {
      if (component?.dataComponent?.seo) {
        return component?.dataComponent?.seo;
      }
    }
  }

  return {};
};

export default I18nContext;
