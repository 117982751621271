export type OmneHeaderCmsDataProps = {
  uid: string;
  background_color: string;
  social_media_links: OmneHeaderSocialMediaLinksCmsProps[];
  sections: OmneHeaderSectionsCmsProps[];
  sub_footer_links: OmneHeaderSubFooterLinksCmsProps[];
  copyright_text: string;
  logo: string;
  navgigation_sections: OmneHeaderNavigationSectionsCmsProps[];
};

export type OmneHeaderSocialMediaLinksCmsProps = {
  social_media: OmneHeaderSocialMediaCmsProps;
};

export type OmneHeaderSocialMediaCmsProps = {
  icon_new: string;
  link: {
    title: string;
    ga_label: string;
    href: string;
  };
};

export type OmneHeaderSectionsCmsProps = {
  major_grouping: OmneHeaderSectionGroupCmsProps[];
};

export type OmneHeaderNavigationSectionsCmsProps = {
  title_link: OmneHeaderTitleLinkCmsProps[];
}

export type OmneHeaderTitleLinkCmsProps = {
  link: {
    title: string;
    url: string;
  },
  open_link_in_new_tab: boolean;
  ga_label: string;
}

export type OmneHeaderSectionGroupCmsProps = {
  sub_links: OmneHeaderSubLinkCmsProps[];
  major_grouping_link: OmneHeaderMajorGroupingLinkCmsProps;
};

export type OmneHeaderSubFooterLinksCmsProps = {
  link: OmneHeaderSubFooterLinkCmsProps;
};

export type OmneHeaderSubLinkCmsProps = {
  sub_link: {
    link: {
      title: string;
      href: string;
      ga_label: string;
      open_in_new_tab: boolean;
    };
  };
};

export type OmneHeaderMajorGroupingLinkCmsProps = {
  title: string;
  href: string;
};

export type OmneHeaderSubFooterLinkCmsProps = {
  link: {
    title: string;
    href: string;
    ga_label: string;
    open_in_new_tab: boolean;
  }[];
};

export type OmneHeaderDataProps = {
  uid: string;
  backgroundColor: string;
  socialMediaLinks: OmneHeaderSocialMediaProps[];
  sections: OmneHeaderSectionGroupProps[];
  subFooterLinks: OmneHeaderSubFooterLinkProps[];
  copyrightText: string;
  logo: string;
  logoUrl: string;
  logoUrlOpenInNewTab: boolean;
  mobileMenuBackgroundImage: string;
  logos: OmneHeaderLogosProps[];
  navigationSections: OmneHeaderNavigationSectionsProps[];
  iconNavigationLinks: OmneHeaderIconNavigationLinksProps[];
  mobileIconNavigationLinks: OmneHeaderIconNavigationLinksProps[];
  topMobileIconNavigationLinks: OmneHeaderIconNavigationLinksProps[];
};

export type OmneHeaderSocialMediaProps = {
  icon: string;
  link: {
    title: string;
    gaLabel: string;
    href: string;
    openInNewTab: boolean;
  };
};

export type OmneHeaderSectionGroupProps = {
  subLinks: OmneHeaderSubLinkProps[];
  majorGroupingLink: OmneHeaderMajorGroupingLinkProps;
};

export type OmneHeaderNavigationSectionsProps = {
  titleLinks: OmneHeaderTitleLinkProps[];
}

export type OmneHeaderIconNavigationLinksProps = {
  iconLinks: OmneHeaderIconNavigationLinkProps[];
}

export type OmneHeaderTitleLinkProps = {
  link: {
    title: string;
    url: string;
    gaLabel: string;
    openInNewTab: boolean;
  };
}
export type OmneHeaderLogosProps = {
  logo: {
    url: string;
  };
  href: string;
  openInNewTab: boolean;
}
export type OmneHeaderIconNavigationLinkProps = {
  link: {
    title: string;
    icon: string;
    url: string;
    gaLabel: string;
    openInNewTab: boolean;
  };
}

export type OmneHeaderSubLinkProps = {
  link: {
    title: string;
    href: string;
    gaLabel: string;
    openInNewTab: boolean;
  };
};

export type OmneHeaderMajorGroupingLinkProps = {
  type: OmneHeaderGroupTypeEnum;
  title: string;
};

export type OmneHeaderSubFooterLinkProps = {
  title: string;
  href: string;
  gaLabel: string;
  openInNewTab: boolean;
};

export enum OmneHeaderGroupTypeEnum {
  LINK = "[[link]]",
  SOCIAL = "[[social]]",
}

export enum OmneHeaderNavigationTypeEnum {
  MOBILE = "mobile",
  TOP_MOBILE = "top_mobile"
}

export type OmneHeaderGroupProps = {
  type: OmneHeaderGroupTypeEnum;
  title: string;
  items: OmneHeaderGroupItemProps[];
};

export type OmneHeaderGroupItemProps =
  | OmneHeaderSubLinkProps
  | OmneHeaderSocialMediaProps;
