import styled from "styled-components";
import { breakpoint } from "../responsive";
import DynamicDiv from "../DynamicDiv/DynamicDiv";
import { DynamicDivProps } from "../DynamicDiv/DynamicDiv.types";
import { TextProps } from "./Text.types";
import colors from "../colors";
import React, { useMemo } from "react";

const breakpointUtils = (bp) =>
  breakpoint(bp)`
    ${(props: TextProps) => {
      let styles = "";

      if (props[`${bp}FontSize`]) {
        styles += `font-size: ${props[`${bp}FontSize`]} !important;`;
      }

      if (props[`${bp}Color`]) {
        styles += `color : ${props[`${bp}Color`]} !important;`;
      }

      if (props[`${bp}LineHeight`]) {
        styles += `line-height : ${props[`${bp}LineHeight`]} !important;`;
      }

      return styles;
    }}`;
const StyledText = styled(DynamicDiv)<DynamicDivProps & TextProps>`
  font-style: ${(props) => (props.fontStyle ? props.fontStyle : "normal")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "300")};
  letter-spacing: normal !important;

  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  text-decoration: ${(props) => props.textDecoration};
  word-wrap: ${(props) => (props.wordWrap ? props.wordWrap : "normal")};

  color: ${(props) =>
    props.color ? props.color : colors.darkGreen} !important;
  font-size: ${(props) =>
    props.xsFontSize ? props.xsFontSize : "1rem"} !important;
  line-height: ${(props) =>
    props.lineHeight ? props.lineHeight : "24px"} !important;
  white-space: ${(props) =>
    props.whiteSpace ? props.whiteSpace : "initial"} !important;
  display: ${(props) => (props.display ? props.display : "inherit")};
  ${["sm", "md", "lg", "xl"].map((o) => breakpointUtils(o))}
`;

const Text: React.FC<DynamicDivProps & TextProps> = React.forwardRef(
  ({ seoHeader, isParagraph, children, ...props }, ref) => {
    const asComponent = useMemo(() => {
      if (isParagraph) {
        return "p";
      } else {
        return seoHeader ? seoHeader : DynamicDiv;
      }
    }, [isParagraph, seoHeader]);

    props.xsFontSize = props.fontSize;

    return (
      <>
        {typeof children === "string" ? (
          <StyledText
            {...props}
            ref={ref}
            dangerouslySetInnerHTML={{ __html: children }}
            as={asComponent}
          />
        ) : (
          <StyledText {...props} ref={ref} as={asComponent}>
            {children}
          </StyledText>
        )}
      </>
    );
  }
);
export default Text;
