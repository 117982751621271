import { replaceContentStackURL } from "@utils";
import {
  resolveAbsoluteUrlInParagraphServer,
  resolveAbsoluteUrlServer,
} from "utils/route";
import {
  CMSTabbedContentSection,
  isTabBodyButton,
  isTabBodyHeading,
  isTabBodyText,
  isTabBodyTips,
  TabbedContentSectionData,
} from "./TabbedContentSection.type";

export function transformTabbedContentSectionData(
  data: CMSTabbedContentSection,
  languageCode: string,
  numberOfLang: number
): TabbedContentSectionData {
  const cmsData = data.reference_6?.[0];
  if (!cmsData) {
    return {
      title: "",
      tabs: [],
    };
  }

  const defaultImage = { url: "", description: "" };

  return {
    title: cmsData.display_title || cmsData.title,
    tabs: cmsData.tabs.map((block) => {
      const tab = block.tab;
      const desktopImage = tab.desktop_image || defaultImage;
      const mobileImage = tab.mobile_image || defaultImage;

      return {
        title: tab.title,
        uid: tab._metadata.uid,
        desktopImage: {
          url: replaceContentStackURL(desktopImage.url),
          description: desktopImage.description,
        },
        mobileImage: {
          url: replaceContentStackURL(mobileImage.url),
          description: mobileImage.description,
        },
        body: tab.body.map((body) => {
          if (isTabBodyText(body)) {
            return {
              type: "TabBodyText",
              text: resolveAbsoluteUrlInParagraphServer(
                body.body_text.text,
                languageCode,
                numberOfLang
              ),
            };
          } else if (isTabBodyHeading(body)) {
            return {
              type: "TabBodyHeading",
              headingText: body.heading_text.text,
            };
          } else if (isTabBodyTips(body)) {
            return {
              type: "TabBodyTips",
              items: body.tips.items.map((item) => ({
                icon: item.icon,
                text: item.text,
              })),
            };
          } else if (isTabBodyButton(body)) {
            return {
              type: "TabBodyButton",
              title: body.button.title,
              openInNewTab: body.button.open_in_new_tab,
              icon: body.button.icon,
              gaLabel: body.button.ga_label,
              url: resolveAbsoluteUrlServer(
                body.button.url,
                languageCode,
                numberOfLang
              ),
            };
          }
        }) as TabbedContentSectionData["tabs"][number]["body"],
      };
    }),
  };
}
