export enum PageType {
  Articles = "articles",
  Products = "products",
  Claims = "claims",
  Support = "support",
  GroupInsurance = "group-insurance",
  Landing = "landing",
}

export enum ProductAffiliation {
  Insurance = "insurance",
  InsuranceKey = "[FIB]",
  Takaful = "takaful",
  TakafulKey = "[FWDT]",
  CombineKey = "Insurance/Takaful",
}

export enum ProductBranch {
  FWD = "FWD"
}

export enum CSBranchName {
  Capricorn = "capricorn",
}
