import React from 'react';
import { ErrorMessageProps } from './ErrorMessage.types';
import DynamicDiv from '../DynamicDiv/DynamicDiv';
import Text from '../Text/Text';
import colorsUtils from '../colors';
import Exclamation from '../../public/error-exclamation.svg';

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => (
  <DynamicDiv display="flex" alignItems="baseline" xsMargin="4px 0 0 0">
    <div>
      <Exclamation />
    </div>
    <Text
      xsFontSize="0.75rem"
      color={colorsUtils.alertRed}
      xsMargin="0 0 0 0.25rem"
      lineHeight="21px"
      alignItems="center"
      display="flex"
    >
      {message}
    </Text>
  </DynamicDiv>
);

export default ErrorMessage;
