export enum AnimatedMarketingBlockButtonType {
  Default = "default",
  ImageButton = "image-button",
}

export enum Animation {
  PHHomePage = "ph-homepage",
}

export interface CmsAnimatedMarketingBlockData {
  reference_5: [
    {
      title: string;
      display_title: string;
      description: string;
      app_icon: {
        url: string;
      };
      buttons: {
        type: AnimatedMarketingBlockButtonType;
        label: string;
        url: string;
        ga_label: string;
        open_in_new_tab: boolean;
        button_image: {
          url: string;
        };
      }[];
      animated_images: {
        animation: Animation;
        layers: {
          images: {
            image: { url: string };
            title: string;
            subtitle: string;
            url: string;
          }[];
        }[];
      };
    }
  ];
}

export interface AnimatedMarketingBlockData {
  title: string;
  description: string;
  appIcon: string;
  buttons: {
    type: AnimatedMarketingBlockButtonType;
    label: string;
    url: string;
    gaLabel: string;
    openInNewTab: boolean;
    buttonImage: string;
  }[];
  animatedImages: {
    animation: Animation;
    layers: {
      images: {
        imageUrl: string;
        title?: string;
        subtitle?: string;
        navigateUrl?: string;
        openInNewTab?: boolean;
      }[];
    }[];
  };
}
