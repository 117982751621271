import { colorsUtils } from "@d2c-ui-components-react";

const colors = {
  secondaryFwdOrange: "#e8772233",
  fwdOrange50: "#F3BB90",
};

export default { ...colorsUtils, ...colors };

export const hexToRgba = (hex: string, alpha: number): string => {
  hex = hex.replace(/^#/, "");

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  alpha = Math.min(1, Math.max(0, alpha));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
