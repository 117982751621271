import React, { ReactNode } from 'react';
import { TextLinkProps } from './TextLink.types';
import { StyledButton, StyledIcon } from './TextLink.styled';
import clsx from 'clsx';

const defaultProps = {
  primary: true,
  large: false,
  darkMode: false
};

const TextLink: React.FC<TextLinkProps> = props => {
  const { children, icon, primary, large, darkMode, onClick, styles: propStyles, SvgIcon } = props;

  const buttonProps = {
    // Fix to prevent passing non-string attributes to DOM element
    // Check usage of $ at https://styled-components.com/docs/api#transient-props
    $primary: primary,
    $darkMode: darkMode,
    $large: large,
    onClick,
    type: 'button',
    variant: 'link',
    style: propStyles?.container || {}
  };

  const renderIcon = (): ReactNode => {
    if (!icon && !SvgIcon) {
      return;
    }

    if (!!SvgIcon) {
      return SvgIcon;
    } else {
      return <StyledIcon icon={['fas', icon!]} fixedWidth />;
    }
  };

  return (
    <StyledButton data-testid="TextLink" {...buttonProps}>
      <div>{renderIcon()}</div>
      <div className={clsx({ textWithIcon: !!icon || !!SvgIcon })}>{children}</div>
    </StyledButton>
  );
};

TextLink.defaultProps = defaultProps;

export default TextLink;
