import React from "react";

export function useHover<T extends HTMLElement>(): [
  boolean,
  React.RefObject<T>
] {
  const [hovering, setHovering] = React.useState(false);
  const ref = React.useRef<T>(null);

  React.useEffect(() => {
    const node = ref.current;

    if (!node) return;

    const handleMouseEnter = () => {
      setHovering(true);
    };

    const handleMouseLeave = () => {
      setHovering(false);
    };

    node.addEventListener("mouseenter", handleMouseEnter);
    node.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      node.removeEventListener("mouseenter", handleMouseEnter);
      node.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return [hovering, ref];
}
