import { breakpoint, colorsUtils } from "@d2c-ui-components-react";
import DOMPurify from "isomorphic-dompurify";
import {
  ArticleCardListColorVariant,
  ArticleCardListSectionData,
  ArticleCardVariant
} from "types/ArticleCardList.interface";
import { ArticleCard } from "./ArticleCardV3";
import styled from "styled-components";
import { FwdSwiper, FwdSwiperItem } from "shared-components/FwdSwiper";
import { breakpointList } from "@d2c-ui-components-react";
import { fwdColors } from "@fwd-dep/nextgen-ui-lib";
import { BlockContainer } from "shared-components/BlockContainer";
import { FwdLinkButton } from "shared-components/FwdLinkButton";

const ColorVariantClass = {
  [ArticleCardListColorVariant.GreyBackground]: "grey-variant",
  [ArticleCardListColorVariant.OrangeBackground]: "orange-variant",
  [ArticleCardListColorVariant.Custom]: "custom-variant"
};

function getColorVariantClass(colorVariant: ArticleCardListColorVariant) {
  return ColorVariantClass[colorVariant];
}

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  > .link-tag {
    display: none;
    ${breakpoint("sm")`
      display: block;
    `}
  }
`;

const Container = styled.div<{ titleColor: string; bgColor: string }>`
  position: relative;

  .fwd-swiper-wrapper {
    margin: 0px -16px;

    ${breakpoint("md")`
      margin: 0px -12px;
    `}

    @media screen and (max-width: 1200px) {
      .slick-arrow {
        display: none !important;
      }
    }
  }

  &.${getColorVariantClass(ArticleCardListColorVariant.OrangeBackground)} {
    --background-color: ${colorsUtils.fwdOrange};
    --foreground-color: ${colorsUtils.fwdWhite};
    --dot-normal-color: ${colorsUtils.secondaryColorFwdGrey};
    --dot-active-color: ${colorsUtils.fwdWhite};
    --arrow-color: ${colorsUtils.fwdWhite};
    --arrow-hover-background: #dbdfe133;
    --bullet-normal-color: ${colorsUtils.fwdOrange50};
    --bullet-active-color: ${colorsUtils.fwdWhite};
  }

  &.${getColorVariantClass(ArticleCardListColorVariant.GreyBackground)} {
    --background-color: ${({ bgColor }) =>
      bgColor || colorsUtils.secondaryColorFwdGrey};
    --foreground-color: ${({ titleColor }) =>
      titleColor || colorsUtils.darkGreen};
    --dot-normal-color: ${colorsUtils.fwdWhite};
    --dot-active-color: ${colorsUtils.fwdOrange};
    --arrow-color: ${colorsUtils.fwdOrange};
    --arrow-hover-background: ${colorsUtils.secondaryFwdOrange};
    --bullet-normal-color: ${colorsUtils.fwdWhite};
    --bullet-active-color: ${colorsUtils.fwdOrange};
  }

  &.${getColorVariantClass(ArticleCardListColorVariant.Custom)} {
    --background-color: ${({ bgColor }) =>
      bgColor || colorsUtils.secondaryColorFwdGrey};
    --foreground-color: ${({ titleColor }) =>
      titleColor || colorsUtils.darkGreen};
    --dot-normal-color: ${colorsUtils.fwdWhite};
    --dot-active-color: ${colorsUtils.fwdOrange};
    --arrow-color: ${({ bgColor }) =>
      bgColor?.toLocaleLowerCase() === colorsUtils.fwdOrange
        ? colorsUtils.fwdWhite
        : colorsUtils.fwdOrange};
    --arrow-hover-background: ${({ bgColor }) =>
      bgColor?.toLocaleLowerCase() === colorsUtils.fwdOrange
        ? "#dbdfe133"
        : colorsUtils.secondaryFwdOrange};
    --bullet-normal-color: ${colorsUtils.fwdWhite};
    --bullet-active-color: ${colorsUtils.fwdOrange};
  }

  background-color: var(--background-color);
  color: var(--foreground-color);
  position: relative;

  ${SectionTitle} {
    > div {
      color: var(--foreground-color) !important;
    }
  }

  .MuiSvgIcon-root {
    &:hover {
      background-color: var(--arrow-hover-background) !important;
    }
  }
`;

const StyledBlockContainer = styled(BlockContainer)`
  padding-top: 64px;
  padding-bottom: 64px;
`;

const SectionDescription = styled.div`
  margin: 24px 0;
`;

const Title = styled.h2<{
  color?: string;
  textAlign?: string;
  mobileFontSize: number;
  desktopFontSize: number;
}>`
  font-size: ${(p) => p.mobileFontSize}px;
  font-weight: 700;
  margin: 0;
  text-align: ${(p) => p.textAlign || "left"};
  color: ${(p) => p.color || fwdColors.darkGreen};

  ${breakpoint("md")`
    text-align: ${(p) => p.textAlign || "center"};;
    font-size: ${(p) => p.desktopFontSize}px;

  `}
`;

const Footer = styled.div`
  margin-top: 34px;

  ${breakpoint("sm")`
    display: none;
  `}
`;

export default function ArticleCardListV3({ data }: { data: ArticleCardListSectionData }) {
  const { ctaButton } = data;
  const isFourCardSwiper =
    data.cardVariant === ArticleCardVariant.FourCardSwiper;

  const ctaButtonComponent = (
    <FwdLinkButton
        className="cta-button"
      iconName={ctaButton?.icon}
      href={ctaButton?.url}
      iconPosition="left"
      type="secondary"
      backgroundColor={data?.backgroundColor}
      gtmComponentName="ArticleCardList"
      gaEventLabel={ctaButton?.gaLabel}
      openInNewTab={ctaButton?.openInNewTab}
    >
      {ctaButton?.label}
    </FwdLinkButton>
  );

  return (
    <Container
      className={getColorVariantClass(data?.colorVariant)}
      titleColor={data?.titleColor}
      bgColor={data?.backgroundColor}
    >
      <StyledBlockContainer overflowHidden>
        <SectionTitle>
          <Title
            color={data?.titleColor}
            mobileFontSize={31}
            desktopFontSize={isFourCardSwiper ? 39 : 31}
            textAlign={data?.titleTextAlign}
          >
            {data?.title}
          </Title>
          {ctaButtonComponent}
        </SectionTitle>
        {data?.description && (
          <SectionDescription>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data?.description, {
                  ADD_ATTR: ["target"],
                }),
              }}
            />
          </SectionDescription>
        )}
        <FwdSwiper
          useTransform
          slidesToShow={4}
          overflowArrowButton
          backgroundColor={data?.backgroundColor}
          responsive={[
            {
              breakpoint: breakpointList.lg,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: breakpointList.sm,
              settings: {
                slidesToShow: 1,
                centerMode: true,
                infinite: true,
                centerPadding: "30px",
              },
            },
          ]}
          spaceBetween={24}
        >
          {data && data.items?.map((item, index) => {
            return (
              <FwdSwiperItem key={index} spacing={24}>
                <ArticleCard
                  key={index}
                  data={item}
                  cardVariant={data?.cardVariant}
                  searchTab={data?.searchTab}
                />
              </FwdSwiperItem>
            );
          })}
        </FwdSwiper>
        <Footer>{ctaButtonComponent}</Footer>
      </StyledBlockContainer>
    </Container>
  );
}
