import { Maybe } from "@types";

export interface ArticleCategory {
  uid: string;
  name: string;
  searchValue: string;
}

export enum ArticleSearchBehavior {
  JumpToSearchPage = "jump-to-search-page",
  JumpToCustomPage = "jump-to-custom-page",
}

export interface ArticleCardData {
  title: string;
  titleGALabel?: string;
  description?: Maybe<string>;
  desktopImage: { url: string; description?: string };
  mobileImage: { url: string; description?: string };
  tags: { title: string; searchValue: string }[];
  category: ArticleCategory;
  thumbnail?: { url: string; description?: string };
  ctaUrl: string;
  postDate: string;
  publishedDate: string;
  ctaButton?: {
    gaLabel?: string;
    iconName?: string;
    openInNewTab?: boolean;
    url?: string;
    title?: string;
  };
  pageType?: {
    title: string;
    filter_key: string;
  }[];
  searchBehavior: {
    type: ArticleSearchBehavior;
    baseUrl: string;
  };
  id?: string;
}

export enum ArticleCardVariant {
  Large = "Large",
  Medium = "Medium",
  Small = "Small",
  MediumNew = "MediumNew",
  MediumFourCards = "MediumFourCards",
  FourCardSwiper = "FourCardSwipper",
}

export enum ArticleCardListColorVariant {
  OrangeBackground = "orange",
  GreyBackground = "grey",
  Custom = "custom",
}

export interface ArticleCardListSectionData {
  designDisplay: string;
  title: string;
  titleColor: string;
  subtitle: string;
  articleTitle?: string;
  iconTitle?: string;
  backgroundColor: string;
  description?: string;
  items: ArticleCardData[];
  cardVariant: ArticleCardVariant;
  colorVariant: ArticleCardListColorVariant;
  searchTab: any;
  ctaText: string;
  titleTextAlign?: "left" | "right" | "center";
  ctaButton?: {
    label?: string;
    url?: string;
    icon?: string;
    openInNewTab: boolean;
    gaLabel?: string;
  };
}

interface ArticleCardDataV2 {
  title: string;
  description?: Maybe<string>;
  tags: { title: string; searchValue: string }[];
  category: ArticleCategory;
  picture: any;
  ctaUrl: string;
  postDate: string;
}

export interface ArticleCardListSectionDataV2 {
  title: string;
  titleColor: string;
  backgroundColor: string;
  description?: string;
  items: ArticleCardDataV2[];
  cardVariant: ArticleCardVariant;
  colorVariant: ArticleCardListColorVariant;
  searchTab: any;
}
