import CryptoJS from "crypto-js";
import {
  GetServerSideProps,
  GetServerSidePropsContext,
  GetServerSidePropsResult,
  GetStaticProps,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from "next";
import { AppProps } from "next/app";
import React, { useMemo } from "react";

export const KEY_LENGTH = 6;

interface AppEncryptionProviderProps<T>
  extends AppProps<WithEncryptionProps<T>> {}
export const AppEncryptionProvider: React.FC<
  AppEncryptionProviderProps<any>
> = (props) => {
  const decryptedData = useMemo(() => {
    try {
      if (!props.pageProps.timestamp) {
        return props.pageProps;
      }
      const { timestamp, data } = props.pageProps;
      const key = timestamp.toString().substring(0, KEY_LENGTH);
      const decryptedData = JSON.parse(decryptData(data, key));
      return decryptedData;
    } catch (e) {
      console.error(`Error - ${e}`);
      return props.pageProps;
    }
  }, [props]);
  if (!React.isValidElement(props.children)) {
    return <></>;
  }

  return (
    <>
      {React.cloneElement(props.children, {
        ...props,
        pageProps: decryptedData,
      } as any)}
    </>
  );
};

function encryptData(secretData: string, secretKey: string) {
  try {
    // Encrypt
    var ciphertext = CryptoJS.Rabbit.encrypt(secretData, secretKey).toString();
    return ciphertext;
  } catch (e) {
    console.error(`Error - ${e}`);
    return "";
  }
}

export function decryptData(encryptedData: string, secretKey: string) {
  try {
    // Decrypt
    var bytes = CryptoJS.Rabbit.decrypt(encryptedData, secretKey);
    var originalData = bytes.toString(CryptoJS.enc.Utf8);
    return originalData;
  } catch (e) {
    console.error(`Error - ${e}`);
    return "";
  }
}

export interface WithEncryptionProps<T> {
  timestamp: number;
  data: string;
}

export type EncryptedGetServerSideProps<T> = GetServerSideProps<
  WithEncryptionProps<T>
>;

export function withEncryptionServerSide<T>(
  handler: (
    context: GetServerSidePropsContext
  ) => Promise<GetServerSidePropsResult<T>>
) {
  const encryptedHandler: EncryptedGetServerSideProps<T> = async function (
    context: GetServerSidePropsContext
  ) {
    const data = await handler(context);

    if ("props" in data) {
      const timestamp = new Date().getTime();
      return {
        props: {
          timestamp,
          data: encryptData(
            JSON.stringify(data.props),
            timestamp.toString().substring(0, KEY_LENGTH)
          ),
        },
      };
    }
    return data;
  };
  return encryptedHandler;
}

export type EncryptedGetStaticProps<T> = GetStaticProps<WithEncryptionProps<T>>;
export function withEncryptionStatic<T>(
  handler: (context: GetStaticPropsContext) => Promise<GetStaticPropsResult<T>>
) {
  const encryptedHandler: EncryptedGetStaticProps<T> = async function (
    context: GetStaticPropsContext
  ) {
    const data = await handler(context);
    if ("props" in data) {
      const timestamp = new Date().getTime();
      return {
        props: {
          timestamp,
          data: encryptData(
            JSON.stringify(data.props),
            timestamp.toString().substring(0, KEY_LENGTH)
          ),
        },
        ...(data.revalidate ? { revalidate: data.revalidate } : {}),
      };
    }
    return data;
  };
  return encryptedHandler;
}
