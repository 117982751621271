import { flatten, unflatten } from 'flat';
import { sha256 } from 'js-sha256';

const maskFields = ['mob', 'email'];

export const hashText = (input: string): string => sha256(input);

export const maskSensitiveData = (data: any) => {
  if (!data) {
    return data;
  }

  try {
    const flattenedResult: any = flatten(data);
    for (const key in flattenedResult) {      
      if (maskFields.some(field => key.includes(field))) {
        flattenedResult[key] = hashText(flattenedResult[key]);
      }
    }
    return unflatten(flattenedResult);
  } catch (error) {
    console.log('[EXCEPTION] maskSensitiveData: ', error);    
    return data;
  }
};

export default {
  hashText,
  maskSensitiveData
};
