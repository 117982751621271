import { replaceContentStackURL } from "@utils";
import { I18nContextData } from "i18n/context/LanguageContext";
import get from "lodash/get";
import {
  ArticleCardData,
  ArticleSearchBehavior,
} from "types/ArticleCardList.interface";
import { Maybe } from "types/common.types";
import { resolveAbsoluteUrl, resolveAbsoluteUrlServer } from "utils/route";

export function transformArticlePageCSData(
  csData: any,
  i18nContext: I18nContextData
): Maybe<ArticleCardData> {
  const banner = csData?.body?.find((l: any) => l.top_banner !== undefined)
    ?.top_banner?.reference?.[0];
  const pageType = csData?.page_type;
  const articleBlock = csData?.body?.find(
    (l: any) => l?.article_block?.reference !== undefined
  )?.article_block?.reference?.[0];

  if (!articleBlock) return null;

  let imageUrl = "";
  let imageDescription = "";
  let titleGALabel = "";

  const rewardDetailMetadata = (
    get(csData, "body") ||
    get(csData, "item[0].body") ||
    []
  ).find((bodyItem: any) => bodyItem["reward_detail_metadata"]);

  if (rewardDetailMetadata) {
    imageUrl = get(rewardDetailMetadata, "reward_detail_metadata.image.url");
    imageDescription = get(
      rewardDetailMetadata,
      "reward_detail_metadata.image.description",
      ""
    );
    titleGALabel = get(
      rewardDetailMetadata,
      "reward_detail_metadata.cta.ga_label"
    );
  }

  const ctaButton = rewardDetailMetadata?.reward_detail_metadata?.cta;

  return {
    id: articleBlock.uid,
    category: {
      name: articleBlock?.article_category?.[0]?.title || "",
      uid: articleBlock?.article_category?.[0]?.uid || "",
      searchValue: articleBlock?.article_category?.[0]?.filter_value || "",
    },
    ctaUrl: resolveAbsoluteUrl(
      csData?.global_field?.seo?.canonical_url || "",
      i18nContext
    ),

    ctaButton,
    desktopImage: {
      url: replaceContentStackURL(banner?.banner_desktop?.url || ""),
      description: banner?.banner_desktop?.description || "",
    },
    mobileImage: {
      url: replaceContentStackURL(banner?.banner_mobile?.url || ""),
      description: banner?.banner_mobile?.description || "",
    },
    thumbnail: {
      url: replaceContentStackURL(imageUrl),
      description: imageDescription,
    },
    tags: (articleBlock?.tag || []).map((tag: any) => ({
      title: tag?.title || "",
      searchValue: tag?.filter_value || tag?.title || "",
    })),
    title: articleBlock?.display_title || "",
    titleGALabel,
    description: articleBlock?.short_description || "",
    postDate: articleBlock?.post_date || "",
    publishedDate: articleBlock?.created_at  || "",
    pageType,
    searchBehavior: {
      type:
        (articleBlock.search_behavior
          ?.type as unknown as ArticleSearchBehavior) ||
        ArticleSearchBehavior.JumpToSearchPage,
      baseUrl: articleBlock.search_behavior?.base_url || "/blog",
    },
  };
}
export function transformArticlePageCSDataServer (
  csData: any,
  languageCode: string,
  numberOfLang: number
): Maybe<ArticleCardData> {
  const banner = csData?.body?.find((l: any) => l.top_banner !== undefined)
    ?.top_banner?.reference?.[0];
  const pageType = csData?.page_type;
  const articleBlock = csData?.body?.find(
    (l: any) => l?.article_block?.reference !== undefined
  )?.article_block?.reference?.[0];

  if (!articleBlock) return null;

  let imageUrl = "";
  let imageDescription = "";
  let titleGALabel = "";

  const rewardDetailMetadata = (
    get(csData, "body") ||
    get(csData, "item[0].body") ||
    []
  ).find((bodyItem: any) => bodyItem["reward_detail_metadata"]);

  if (rewardDetailMetadata) {
    imageUrl = get(rewardDetailMetadata, "reward_detail_metadata.image.url");
    imageDescription = get(
      rewardDetailMetadata,
      "reward_detail_metadata.image.description",
      ""
    );
    titleGALabel = get(
      rewardDetailMetadata,
      "reward_detail_metadata.cta.ga_label"
    );
  }

  const ctaButton = rewardDetailMetadata?.reward_detail_metadata?.cta;

  return {
    id: articleBlock.uid,
    category: {
      name: articleBlock?.article_category?.[0]?.title || "",
      uid: articleBlock?.article_category?.[0]?.uid || "",
      searchValue: articleBlock?.article_category?.[0]?.filter_value || "",
    },
    ctaUrl: resolveAbsoluteUrlServer(
      csData?.global_field?.seo?.canonical_url || "",
      languageCode,
      numberOfLang,
    ),

    ctaButton,
    desktopImage: {
      url: replaceContentStackURL(banner?.banner_desktop?.url || ""),
      description: banner?.banner_desktop?.description || "",
    },
    mobileImage: {
      url: replaceContentStackURL(banner?.banner_mobile?.url || ""),
      description: banner?.banner_mobile?.description || "",
    },
    thumbnail: {
      url: replaceContentStackURL(imageUrl),
      description: imageDescription,
    },
    tags: (articleBlock?.tag || []).map((tag: any) => ({
      title: tag?.title || "",
      searchValue: tag?.filter_value || tag?.title || "",
    })),
    title: articleBlock?.display_title || "",
    titleGALabel,
    description: articleBlock?.short_description || "",
    postDate: articleBlock?.post_date || "",
    publishedDate: articleBlock?.created_at  || "",
    pageType,
    searchBehavior: {
      type:
        (articleBlock.search_behavior
          ?.type as unknown as ArticleSearchBehavior) ||
        ArticleSearchBehavior.JumpToSearchPage,
      baseUrl: articleBlock.search_behavior?.base_url || "/blog",
    },
  };
}

export const buildSearchArticleUrl = (
  {
    filterKey,
    categorySearchValue,
    tagSearchValue,
    searchBehavior,
  }: {
    filterKey: string;
    categorySearchValue: string;
    tagSearchValue: string;
    searchBehavior: { type: ArticleSearchBehavior; baseUrl: string };
  },
  i18nContext: I18nContextData
) => {
  if (searchBehavior.type === ArticleSearchBehavior.JumpToSearchPage) {
    return resolveAbsoluteUrl(
      `/search?contenttype=${filterKey || ""}&subtype=${
        categorySearchValue || ""
      }&tag=${tagSearchValue || ""}`,
      i18nContext
    );
  }

  return resolveAbsoluteUrl(
    `${searchBehavior.baseUrl.replace(/\/$/, "")}/${encodeURIComponent(
      categorySearchValue
    )}#${encodeURIComponent(tagSearchValue)}`,
    i18nContext
  );
};
