import { replaceContentStackURL } from "@utils";
import axios from "axios";
import { SchemaTypes } from "components/HeaderSchema";
import get from "lodash/get";
import { getConfiguration } from "page-services/configuration.service";
import { PageType } from "types/PageType.enum";
import { getFormatDate } from "utils/string";

export enum VideoChannel {
  YOUTUBE = "youtube",
  BRIGHTCOVE = "brightcove",
  MEDIA = "contentstack",
}

export type VideoData = {
  type: VideoChannel;
  videoId: string;
  accountId?: string;
  playerId?: string;
  thumbnailUrl?: string;
};

export type FaqData = {
  data: {
    question: string;
    answer: string;
  }[];
};

export type BlogData = {
  url: string;
  //logo: string;
  headline: string;
  description: string;
  image: string;
  datePublished: string;
  dateModified: string;
  author: {
    type: AuthorType;
    name: string;
    url: string;
  };
  publisher: {
    type: AuthorType;
    name: string;
    logo: {
      url: string;
    };
  };
};

export enum BlogEntityType {
  BLOG_POSTING = "BlogPosting",
  WEB_PAGE = "WebPage",
}

export enum AuthorType {
  ORGANIZATION = "Organization",
  PERSON = "Person",
}

export enum ImageType {
  IMAGE_OBJECT = "ImageObject",
}

export type BlogObjectSchema = {
  "@context": string;
  "@type": BlogEntityType;
  mainEntityOfPage: {
    "@type": BlogEntityType;
    "@id": string;
  };
  headline: string;
  description: string;
  image: string;
  author: {
    "@type": AuthorType;
    name: string;
    url: string;
  };
  publisher: {
    "@type": AuthorType;
    name: string;
    logo: {
      "@type": string;
      url: string;
    };
  };
  datePublished: string;
  dateModified: string;
};

export type FaqObjectSchema = {
  "@context": string;
  "@type": EntityType;
  mainEntity: FaqEntity[];
};

export type VideoObjectSchema = {
  "@context": string;
  "@type": string;
  name: string;
  description: string;
  thumbnailUrl: string;
  uploadDate: string;
  duration: string;
  contentUrl: string;
};

export type FaqEntity = {
  "@type": EntityType;
  name: string;
  acceptedAnswer: AnswerEntity;
};

export type AnswerEntity = {
  "@type": EntityType;
  text: string;
};

export enum EntityType {
  FAQ = "FAQPage",
  QUESTION = "Question",
  ANSWER = "Answer",
}

export enum SchemaType {
  FAQ = "faq",
  BLOG = "blog",
  VIDEO = "video",
}

export async function generateSchema(
  data: FaqData | BlogData | VideoData,
  type: SchemaType
): Promise<SchemaTypes[]> {
  const API_URL = getConfiguration(
    "NEXT_PUBLIC_API_URL",
    process.env.NEXT_PUBLIC_API_URL
  );

  const API_AUTH_BASE64 =
    getConfiguration(
      "NEXT_PUBLIC_API_AUTH_BASE64",
      process.env.NEXT_PUBLIC_API_AUTH_BASE64
    ) || "";

  try {
    let apiEndpoint: string | null = null;

    switch (type) {
      case SchemaType.FAQ:
        apiEndpoint = `${API_URL}/api/schema/faq`;
        break;
      case SchemaType.BLOG:
        apiEndpoint = `${API_URL}/api/schema/blog`;
        break;
      case SchemaType.VIDEO:
        apiEndpoint = `${API_URL}/api/schema/video`;
        break;
      default:
        apiEndpoint = null;
    }

    if (!apiEndpoint) {
      return [];
    }

    const response = await axios.post(
      apiEndpoint,
      {
        data,
      },
      {
        headers: {
          Authorization: `Basic ${API_AUTH_BASE64}`,
        },
      }
    );

    if (response?.data?.result) {
      return response.data.result;
    }
    return [];
  } catch (e: any) {
    return [];
  }
}

export const getBlogSchemaFromArticleDetailPage = (
  components: any,
  i18nContextData: any,
  host: string,
  isSSG: boolean
): BlogData | null => {
  const articleBlock = components?.layout?.find(
    (object: any) => object?.nameComponent === "ArticleBlock"
  )?.dataComponent?.reference?.[0];

  const header = components?.layout?.find(
    (object: any) => object?.nameComponent === "NextHead"
  )?.dataComponent;

  if (
    !!articleBlock?.disable_blog_schema ||
    header?.pageType !== PageType.Articles
  ) {
    return null;
  }

  let logo = components?.layout?.find(
    (object: any) => object?.nameComponent === "CSHeader"
  )?.dataComponent?.logo?.url;

  let image =
    components?.layout?.find(
      (object: any) => object?.nameComponent === "CSHeroBanner"
    )?.dataComponent?.reference?.[0]?.banner_desktop?.url ||
    header?.openGraph?.og_image?.url;

  let publisherLogo =
    i18nContextData?.siteSettings?.schema?.publisher_logo?.url || "";

  let url = header?.seo?.canonical_url;

  if (!!url) {
    url = url[0] === "/" ? url : `/${url}`;

    if (
      !!isSSG &&
      Object.keys(i18nContextData?.supportedLanguages || []).length <= 1
    ) {
      url = `${host}${url}`;
    } else {
      url = `${host}/${i18nContextData?.language?.languageCode || ""}${url}`;
    }
  }

  if (!!image) {
    image = !isSSG
      ? replaceContentStackURL(image)
      : `${host}${replaceContentStackURL(image)}`;
  }

  if (!!logo) {
    logo = !isSSG
      ? replaceContentStackURL(logo)
      : `${host}${replaceContentStackURL(logo)}`;
  }

  if (!!publisherLogo) {
    publisherLogo = !isSSG
      ? replaceContentStackURL(publisherLogo)
      : `${host}${replaceContentStackURL(publisherLogo)}`;
  }

  const headline = get(articleBlock, "display_title", "");
  const description =
    get(articleBlock, "short_description", "") ||
    get(articleBlock, "subtitle", "");

  if (!headline && !description) {
    return null;
  }

  const authorName = get(articleBlock, "author", "Posted by FWD");
  let authorType: string = get(
    articleBlock,
    "author_type",
    AuthorType.ORGANIZATION
  );

  let authorTypeUpdated: AuthorType = AuthorType.ORGANIZATION;

  switch (authorType) {
    case "organization" || AuthorType.ORGANIZATION:
      authorTypeUpdated = AuthorType.ORGANIZATION;
      break;
    case "person" || AuthorType.PERSON:
      authorTypeUpdated = AuthorType.PERSON;
      break;
    default:
      authorTypeUpdated = AuthorType.ORGANIZATION;
      break;
  }

  const authorLink = get(articleBlock, "author_link", "");

  const datePublished = getFormatDate(
    get(articleBlock, "post_date", null) ||
      get(articleBlock, "created_at", null),
    i18nContextData.language.languageCode,
    i18nContextData.countryCode,
    "YYYY/MM/DD"
  );
  const dateModified = getFormatDate(
    get(articleBlock, "updated_at", null),
    i18nContextData.language.languageCode,
    i18nContextData.countryCode,
    "YYYY/MM/DD"
  );

  const data: BlogData = {
    url,
    //logo: replaceContentStackURL(logo),
    headline,
    description,
    image,
    datePublished,
    dateModified,
    author: {
      type: authorTypeUpdated,
      name: authorName,
      url: authorLink,
    },
    publisher: {
      type: AuthorType.ORGANIZATION,
      name: i18nContextData?.siteSettings?.schema?.publisher_name || authorName,
      logo: {
        url: publisherLogo,
      },
    },
  };

  return data;
};
