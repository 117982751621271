import { useEffect, useState } from "react";

export const useIsHydrating = () => {
  const [isHydrating, setIsHydrating] = useState(true);

  // use effect only run if hydrating completed (javascript executed)
  useEffect(() => {
    setIsHydrating(false);
  }, []);

  return isHydrating;
};
