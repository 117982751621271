import { FC } from "react";
import { Link, LinkProps } from "shared-components/Link";
import styled from "styled-components";
import { Button, fwdColors } from "@fwd-dep/nextgen-ui-lib";
import styles from "./FwdLinkButton.module.scss";
import clsx from "clsx";


export interface FwdLinkButtonProps extends LinkProps {
  backgroundColor?: string;
  iconName?: string | any;
  iconPosition?: "left" | "right";
  type?: "primary" | "secondary";
  outlined?: boolean;
}

const TYPE_PROPS = {
  primary: {
    color: "white",
    outlined: false,
  },
  secondary: {
    color: "orange",
    outlined: false,
  },
};

export const FwdLinkButton: FC<FwdLinkButtonProps> = (props) => {
  const {
    children,
    iconName,
    backgroundColor,
    iconPosition = "right",
    className,
    type = "primary",
    outlined = true,
    gaEventLabel,
    ...linkProps
  } = props;

  const typeProps = TYPE_PROPS[type] || {};

  return (
    <Link gaEventLabel={gaEventLabel || (children as string)} {...linkProps}>
      <Button
        className={clsx(className, styles.CtaButton, {
          [styles["CtaButton--primary"]]: type === "primary",
          [styles["CtaButton--icon-right"]]: iconPosition === "right",
        })}
        icon={iconName}
        variant="secondary"
        backgroundColor={backgroundColor}
        buttonType={type}
        iconPosition={iconPosition}
        {...typeProps}
      >
        {children}
      </Button>
    </Link>
  );
};
