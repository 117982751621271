import { Maybe } from "@types";
import { ExportFailedPage, TRedisExportFailureKey } from "types/redis";
import Redis from "ioredis";
import { FetchError } from "contentstack-sdk/common";

let redis: Redis.Redis = {} as Redis.Redis;

const enableRedisCaching =
  (process.env.REDIS_ENABLE_CACHING ?? "false") === "true";
const redisUri = process.env.REDIS_URL ?? "";

if (enableRedisCaching && redisUri) {
  const isSecureRedis = redisUri.startsWith("rediss://");

  try {
    redis = new Redis(redisUri, {
      retryStrategy: () => {
        return undefined;
      },
      ...(isSecureRedis && {
        tls: { checkServerIdentity: () => undefined },
      }),
    });
  } catch (err) {
    console.log(`redis error: ${err}`);
  }
}
export default redis;

export const getStaticRedis = () => {
  const url = process.env.REDIS_URL ?? "";
  const isSecureRedis = url.startsWith("rediss://");
  //console.log({ redisURL: url });
  const redis = new Redis(url, {
    retryStrategy: () => {
      return undefined;
    },
    ...(isSecureRedis && {
      tls: { checkServerIdentity: () => undefined },
    }),
  });
  return redis;
};

export const getRedisExportInstance = async (
  fileKey: TRedisExportFailureKey,
  country: string,
  envName: string,
  buildId: string
) => {
  const redis = await getStaticRedis();
  const key = `${fileKey}:${country}:${envName}:${buildId}`;
  let failedUrls: Maybe<{ locale: string; url: string; reason: string }[]>;
  return {
    isAvailable() {
      return redis.status === "connecting" || redis.status === "ready";
    },
    async clearFailedExportedPages() {
      try {
        await redis.del(key);
      } catch (error) {
        console.error("[ERROR] clearFailedExportedPages: ", { error });
        return [];
      }
    },
    async getFailedExportedPages() {
      try {
        failedUrls = (await redis.lrange(key, 0, -1)).map((entry) =>
          JSON.parse(entry)
        );
        return failedUrls;
      } catch (error) {
        console.error("[ERROR] getFailedExportedPages: ", { error });
        return [];
      }
    },
    async ensureFailedUrlsNotNull<T>(
      callback: (...args: any) => T
    ): Promise<Awaited<T>> {
      if (!failedUrls) {
        failedUrls = await this.getFailedExportedPages();
      }

      return await callback();
    },
    async markFailedPage(locale: string, url: string, failedReason: any) {
      try {
        const reason = failedReason || {};
        await redis.lpush(key, JSON.stringify({ locale, url, reason }));
        return true;
      } catch (error) {
        console.error("[ERROR] markFailedPage: ", { error });
        return false;
      }
    },
    async unmarkFailedPage(entry: {
      locale: string;
      url: string;
      reason: string;
    }) {
      try {
        await redis.lrem(key, 1, JSON.stringify(entry));

        return true;
      } catch (error) {
        console.error("[ERROR] unmarkFailedPage: ", { error });
        return false;
      }
    },
  };
};

// Only use for SSG
export const runWithFailedPageLoggingContext = async <Ret>(
  key: TRedisExportFailureKey,
  languageCode: string,
  currentUrl: string,
  callback: () => Ret
) => {
  const redis = await getRedisExportInstance(
    key,
    (process.env.NEXT_PUBLIC_COUNTRY_CODE || "").toLowerCase(),
    (process.env.NEXT_PUBLIC_CONTENTSTACK_ENV || "").toLowerCase(),
    // this env var is set by Site Generator, in exporting script
    process.env.SITEGENERATOR_JOB_ID || ""
  );
  let failedUrls: ExportFailedPage[] = [];

  if (redis.isAvailable()) {
    failedUrls = await redis.getFailedExportedPages();
  }

  try {
    const res = await callback();

    const storedFailedUrl = failedUrls.find((e) => e.url === currentUrl);
    if (failedUrls && storedFailedUrl) {
      await redis.unmarkFailedPage(storedFailedUrl!);
    }

    return res;
  } catch (error: unknown) {
    const errMessage = (error as Error).message;
    const err = errMessage
      ? [
          {
            error: { error_message: errMessage, trace: (error as Error).stack },
          },
        ]
      : error;
    if (redis.isAvailable()) {
      await redis.markFailedPage(languageCode, currentUrl, err);
    }

    if (JSON.stringify(err).indexOf("Language was not found") >= 0) {
      return {
        notFound: true,
      } as unknown as Ret;
    }

    console.error("");
    console.error(
      `[EXCEPTION][runWithFailedPageLoggingContext] Fetch data for page "${languageCode}${
        currentUrl ? `/${currentUrl}` : ""
      }" failed. More details:`
    );
    console.log({ error: JSON.stringify(err || {}) });
    console.error("");
    throw `Fetch data for page "${languageCode}${
      currentUrl ? `/${currentUrl}` : ""
    }" failed`;
  }
};

export const runWithRetryContext = async <Ret1, Ret2>(
  key: TRedisExportFailureKey,
  handleRetryCallback: (pages: ExportFailedPage[]) => Ret1,
  handleNoRetryCallback: () => Ret2
) => {
  const redis = await getRedisExportInstance(
    key,
    (process.env.NEXT_PUBLIC_COUNTRY_CODE || "").toLowerCase(),
    (process.env.NEXT_PUBLIC_CONTENTSTACK_ENV || "").toLowerCase(),
    // this env var is set by Site Generator, in exporting script
    process.env.SITEGENERATOR_JOB_ID || ""
  );

  // control on Site Generator side
  // if no retry, clear the failed urls list
  let failedUrls: ExportFailedPage[] = [];

  if (redis.isAvailable()) {
    failedUrls = await redis.getFailedExportedPages();
  }

  if (failedUrls && failedUrls.length) {
    const res = await handleRetryCallback(failedUrls);
    await redis.clearFailedExportedPages();
    return res;
  } else {
    return handleNoRetryCallback();
  }
};
