import axios from "axios";
import { Redis } from "ioredis";
import getConfig, { setConfig } from "next/config";
import { getStaticRedis } from "services/redis";

const RETRY_COUNT = 3;
const RETRY_MS = 100;

const getRedis = (() => {
  let redis: Redis | undefined;
  return () => {
    if (!redis) {
      redis = getStaticRedis();
    }

    return redis;
  };
})();

const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

const isReady = async (): Promise<boolean> => {
  let attempt = 0;
  let redis = getRedis();
  while (attempt++ < RETRY_COUNT) {
    console.log("configuration - check redis attempt:", attempt);
    if (redis.status === "ready") {
      console.log("configuration - redis is ready");
      return true;
    }
    await wait(RETRY_MS);
  }
  console.log(
    `configuration - redis is not ready after ${RETRY_COUNT} attempts`
  );
  return false;
};

const fetchConfiguration = async (market: string, env: string) => {
  let getEnv = "UAT";
  if (env?.toUpperCase().includes("DEV")) {
    getEnv = "DEV";
  }
  if (env?.toUpperCase().includes("UAT")) {
    getEnv = "UAT";
  }
  if (env?.toUpperCase().includes("PROD")) {
    getEnv = "PROD";
  }

  const url = (process.env.SPRING_CONFIG_API_ENDPOINT || "")
    .replace("{country_code}", market)
    .replace("{environment}", getEnv);

  console.log({ url });

  const response = await axios.get(url);

  if (
    typeof response.data !== "object" ||
    !response.data.propertySources ||
    !response.data.propertySources[0] ||
    !response.data.propertySources[0].source
  ) {
    throw new Error("Fetched invalid configuration");
  }

  return response.data.propertySources[0].source;
};

export const setConfiguration = async (
  cookieId: string,
  market: string | undefined,
  env: string | undefined
): Promise<number> => {
  let redis = getRedis();
  console.log(
    `configuration - market=${market} | env=${env} | cookieId=${cookieId}`
  );
  const ready = await isReady();
  if (!ready) {
    return 500;
  }

  // TODO: to remove this case
  // this is fallback for demo purpose only
  if (!market && !env) {
    if (!cookieId || !(await redis.get(`${cookieId}-configuration`))) {
      market = "th";
      env = "corporate-pages-dev-preview-env";
      console.log(
        "configuration - fallback to market=th and env=corporate-pages-dev-preview-env"
      );
    }
  }

  if (market && env) {
    try {
      const config = getConfig();
      delete config.publicRuntimeConfig;

      const data = await fetchConfiguration(market, env);

      setConfig({
        ...getConfig(),
        publicRuntimeConfig: data,
      });

      console.log({ data, config, newConfig: getConfig() });

      if (cookieId) {
        await redis.set(`${cookieId}-market`, market);
        await redis.set(`${cookieId}-env`, env);
        await redis.set(`${cookieId}-configuration`, JSON.stringify(data));
      }
      console.log("configuration - set new configuration successfully");
      return 200;
    } catch (e) {
      if (cookieId) {
        await redis.set(`${cookieId}-market`, market);
        await redis.set(`${cookieId}-env`, env);
        await redis.del(`${cookieId}-configuration`);
      }
      console.log({ market, env });
      console.log("configuration - failed to fetch configuration");
      return 400;
    }
  }
  if (cookieId) {
    const cache = await redis.get(`${cookieId}-configuration`);
    if (cache) {
      try {
        const data = JSON.parse(cache);
        const config = getConfig();

        setConfig({
          ...getConfig(),
          publicRuntimeConfig: {
            ...config.publicRuntimeConfig,
            ...data,
          },
        });
        const m = await redis.get(`${cookieId}-market`);
        const e = await redis.get(`${cookieId}-env`);
        console.log(
          `configuration - use existing configuration: market=${m} | env=${e}`
        );
        return 200;
      } catch (e) {
        console.log("configuration - failed to parse Redis cache");
        return 500;
      }
    }
  }
  console.log("configuration - no existing configuration");
  return 400;
};

export const getConfiguration = (
  key: string,
  defaultValue?: string
): string => {
  const value = getConfig()?.publicRuntimeConfig?.[key] || defaultValue || "";
  return value;
};
