import isArray from "lodash/isArray";
import isUndefined from "lodash/isUndefined";
import isEmpty from "lodash/isEmpty";
import { NextRouter } from "next/router";
import { LanguageCode, PrefixUrlLanguages } from "i18n/types";
import {
  replaceContentStackURL,
  CONTENSTACK_FILE_URL,
  CONTENSTACK_IMAGE_URL,
} from "./relativePaths";
import { I18nContextData } from "i18n/context/LanguageContext";
import I18nContext from "i18n/context/LanguageContext";
import { useRouter } from "next/router";
import { useContext, useMemo } from "react";

function resolveAbsoluteUrl(
  url: string = "/",
  i18nContext: I18nContextData,
  withoutLastSlash?: boolean,
  langCodeFromUrl?: string
) {
  if (!url) {
    return url;
  }

  if (
    /^(http:\/\/|https:\/\/|mailto:|tel:).*/.test(url) ||
    /^.+\.\w{1,4}$/.test(url)
  ) {
    if (
      url.startsWith(CONTENSTACK_FILE_URL) ||
      url.startsWith(CONTENSTACK_IMAGE_URL)
    ) {
      return replaceContentStackURL(url);
    }
    return url;
  }

  const languageCode = i18nContext?.language?.languageCode || "";
  let relativeUrl = url.replace(/^.*\/\/[^\/]+/, "");
  const withoutPrefixLanguage =
    Object.keys(i18nContext?.supportedLanguages || []).length <= 1 &&
    !langCodeFromUrl;
  const isExistedPrefixLanguage = PrefixUrlLanguages.some((prefixUrlLanguage) =>
    relativeUrl.startsWith(prefixUrlLanguage)
  );
  relativeUrl =
    isExistedPrefixLanguage || withoutPrefixLanguage
      ? relativeUrl
      : `/${languageCode}/${relativeUrl}`;

  if (relativeUrl?.includes("?")) {
    withoutLastSlash = true;
    if (!relativeUrl.includes("/?")) {
      const newUrl = relativeUrl.split("?").join("/?");
      relativeUrl = newUrl;
    }
  }

  const lastRelativeUrl =
    relativeUrl
      .replace(/([^:]\/)\/+/g, "$1")
      .replace("//", "/")
      .replace(/\/$/, "") + (withoutLastSlash ? "" : "/");

  return lastRelativeUrl;
}

export function resolveAbsoluteUrlServer(
  url: string = "/",
  languageCode: string,
  numberOfLang: number,
  withoutLastSlash?: boolean,
  langCodeFromUrl?: string
) {
  if (!url) {
    return url;
  }

  if (
    /^(http:\/\/|https:\/\/|mailto:|tel:).*/.test(url) ||
    /^.+\.\w{1,4}$/.test(url)
  ) {
    if (
      url.startsWith(CONTENSTACK_FILE_URL) ||
      url.startsWith(CONTENSTACK_IMAGE_URL)
    ) {
      return replaceContentStackURL(url);
    }
    return url;
  }

  let relativeUrl = url.replace(/^.*\/\/[^\/]+/, "");
  const withoutPrefixLanguage = numberOfLang <= 1 && !langCodeFromUrl;
  const isExistedPrefixLanguage = PrefixUrlLanguages.some((prefixUrlLanguage) =>
    relativeUrl.startsWith(prefixUrlLanguage)
  );
  relativeUrl =
    isExistedPrefixLanguage || withoutPrefixLanguage
      ? relativeUrl
      : `/${languageCode}/${relativeUrl}`;

  if (relativeUrl?.includes("?")) {
    withoutLastSlash = true;
    if (!relativeUrl.includes("/?")) {
      const newUrl = relativeUrl.split("?").join("/?");
      relativeUrl = newUrl;
    }
  }

  const lastRelativeUrl =
    relativeUrl
      .replace(/([^:]\/)\/+/g, "$1")
      .replace("//", "/")
      .replace(/\/$/, "") + (withoutLastSlash ? "" : "/");

  return lastRelativeUrl;
}

function resolveAbsoluteUrlInParagraph(
  content: string = "",
  i18nContext: I18nContextData
) {
  return content.replace(
    /(href|src)="(.*?)"/g,
    (fullMatch, attribute, urlInAttribute) => {
      const url = urlInAttribute;

      if (url.includes("javascript:void(0)")) return fullMatch;

      if (url.startsWith("#")) {
        return `${attribute}="${url}"`;
      } else if (url.startsWith("http://") || url.startsWith("https://")) {
        return `${attribute}="${replaceContentStackURL(url)}"`;
      }
      return `${attribute}="${resolveAbsoluteUrl(url, i18nContext)}"`;
    }
  );
}

function resolveAbsoluteUrlInParagraphServer(
  content: string = "",
  languageCode: string,
  numberOfLang: number
) {
  return content.replace(
    /(href|src)="(.*?)"/g,
    (fullMatch, attribute, urlInAttribute) => {
      const url = urlInAttribute;

      if (url.includes("javascript:void(0)")) return fullMatch;

      if (url.startsWith("#")) {
        return `${attribute}="${url}"`;
      } else if (url.startsWith("http://") || url.startsWith("https://")) {
        return `${attribute}="${replaceContentStackURL(url)}"`;
      }
      return `${attribute}="${resolveAbsoluteUrlServer(
        url,
        languageCode,
        numberOfLang
      )}"`;
    }
  );
}

const getLanguageFromUrl = (router: NextRouter): LanguageCode => {
  const query = router.query;
  const lang = (query.lang as string).replace("/", "");
  return lang;
};

const getSlugFromUrl = (router: NextRouter): string => {
  const { slug } = router.query;
  if (!slug || !slug.length) return "";
  if (isArray(slug) && slug.length > 1) {
    return slug.join("/");
  }
  return slug[0];
};

const scrollIntoViewByHashFromUrl = (
  defaultHashId?: string,
  timeout: number = 0,
  ofsetTop?: number
) => {
  const offsetTop = ofsetTop || 0;
  setTimeout(() => {
    if (typeof window !== "undefined") {
      let hashId = defaultHashId || extractHashId();

      if (hashId) {
        // Use the hash to find the first element with that id
        try {
          const element = document.querySelector(hashId);
          if (element) {
            // Smooth scroll to that elment
            window.scrollTo({
              top:
                element.getBoundingClientRect().top +
                window.scrollY -
                offsetTop,
              behavior: "smooth",
            });
          }
        } catch (e) {}
      }
    }
  }, timeout);
};

const handleScrollIntoView = (defaultHashId?: string, timeout: number = 0) => {
  setTimeout(() => {
    if (typeof window !== "undefined") {
      let hashId = defaultHashId || window.location.hash;
      if (hashId[hashId.length - 1] === "/") {
        hashId = hashId.slice(0, -1);
      }

      if (hashId) {
        // Use the hash to find the first element with that id
        try {
          const element = document.querySelector(hashId);
          if (element) {
            // Smooth scroll to that elment
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }
        } catch (e) {}
      }
    }
  }, timeout);
};

export const useSlugs = () => {
  const { asPath } = useRouter();

  // copy for prevent unexpected mutate asPath
  const pathUrl = `${asPath}`;
  const i18nContext = useContext(I18nContext);
  const supportedLanguages = Object.keys(i18nContext?.supportedLanguages || {});

  let lang: string | null = null;

  const isPathnameIncludeLanguageCode = supportedLanguages.some((langCode) => {
    const langIncluded = pathUrl.startsWith(`/${langCode}/`);
    if (langIncluded) {
      lang = langCode;
    }
    return langIncluded;
  });

  const slugsUrl = lang ? pathUrl.replace(`/${lang}/`, "/") : pathUrl;

  const slugs: string[] = [];
  let hash: string | null = null;

  slugsUrl
    .split("/")
    .filter((s) => !!s)
    .forEach((s) => {
      // hash inside slugs
      if (s.includes("#")) {
        hash = s.split("#")?.[1];
      }

      slugs.push(s);
    });

  return useMemo(
    () => ({
      lang,
      slugs,
      hash,
      isPathnameIncludeLanguageCode,
      // remove trailing slash
      slugsUrl: slugsUrl.replace(/\/+$/, ""),
    }),
    []
  );
};

export const toQueryParams = (params: Object) => {
  if (isEmpty(params)) {
    return "";
  }

  return Object.entries(params)
    .map(([key, value]) => {
      if (isUndefined(value)) {
        return;
      }

      return `${key}=${value}`;
    })
    .filter((x) => !!x)
    .join("&");
};

export const addQueryParams = (url: string, params: Object) => {
  if (url.includes("?")) {
    return `${url}&${toQueryParams(params)}`;
  }

  return `${url}?${toQueryParams(params)}`;
};

export const jumpToAnchoredElem = (
  hash: string,
  setActiveSection: (sectionIndex: number) => void
) => {
  const anchorPattern = /section:([\d]+)(:[\w\d\s-]+)?(:tab:[\w\d\s-]+)?/;
  const anchorMatches = decodeURI(hash).match(anchorPattern);

  if (!!anchorMatches) {
    const [_, sectionIndex, subSectionId, rest] = anchorMatches;
    if (sectionIndex) {
      setActiveSection(parseInt(sectionIndex, 10));

      setTimeout(() => {
        let subSection = null as any;
        if (subSectionId) {
          subSection = document.getElementById(subSectionId);
        } else {
          subSection = document.querySelector(
            "#horizontal-tab-list-components > section > div:nth-child(1)"
          );
        }

        if (subSection) {
          subSection.scrollIntoView({
            behavior: "smooth",
          });

          if (subSectionId.includes("step-subtitle")) {
            window.scrollBy(0, -120);
          }

          if (rest) {
            const tabAnchoringId = rest.replace(":tab:", "");
            setTimeout(() => {
              const tabNavItem = document.getElementById(
                `tab-nav-${tabAnchoringId}`
              );

              if (tabNavItem) {
                tabNavItem.click();
              }
            }, 600);
          }
        }
      }, 800);
    }
  }
};

const compareHashAndAnchor = (anchor: string) => {
  let hashId = extractHashId();
  if (hashId === `#${anchor}`) {
    return true;
  }
  return false;
};

const extractHashId = () => {
  if (typeof window !== "undefined") {
    let hashId = window.location.hash;
    if (hashId[hashId.length - 1] === "/") {
      hashId = hashId.slice(0, -1);
    }
    return hashId;
  }
};

export {
  resolveAbsoluteUrl,
  resolveAbsoluteUrlInParagraph,
  resolveAbsoluteUrlInParagraphServer,
  getLanguageFromUrl,
  getSlugFromUrl,
  scrollIntoViewByHashFromUrl,
  handleScrollIntoView,
  compareHashAndAnchor,
  extractHashId,
};
