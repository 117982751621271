import React, { useRef, useEffect, useState } from 'react';
import { DropdownProps } from './Dropdown.types';
import { generateStyles } from './Dropdown.style';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import clsx from 'clsx';
import DropdownIcon  from '../../public/dropdown.svg';
import '../fontawesome';
import Tick from '../../public/dropdown-selected-tick.svg';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const defaultProps = {
  value: '',
  variant: 'outlined' as const
};

const Dropdown: React.FC<DropdownProps> = props => {
  const {
    label,
    variant,
    onChange,
    onBlur,
    options,
    value,
    disabled,
    errMsg,
    htmlAttributes,
    styles,
    displayValue,
    name,
    selectedValue,
    color,
    type,
  } = props;

  const [optionWidth, setOptionWidth] = useState(100);

  const useStyle = makeStyles(generateStyles(styles, optionWidth));

  const classes = useStyle();

  const dropdownRef = useRef<any>(null);

  useEffect(() => {
    if (dropdownRef.current) {
      setOptionWidth(dropdownRef.current.offsetWidth);
    }
  }, [dropdownRef]);

  const handleOnChange = e => {
    if (onBlur) {
      onBlur(e);
    }

    if (onChange) {
      onChange(e);
    }
  };

  const MenuProps = {
    transitionDuration: 300, // to match the motionMoveDownAndFade timings
    classes: {
      paper: clsx(classes.menuItems, classes.dropdownShadow, classes.scrollBar, variant === 'standard' && 'standard')
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    disableScrollLock: false,
    disablePortal: false
  };

  const selectProps: any = {
    onChange: handleOnChange,
    label,
    IconComponent: DropdownIcon,
    MenuProps,
    value: selectedValue || value,
    onBlur,
    name,
    inputProps: { ...htmlAttributes },
    ...(displayValue && { renderValue: () => <>{displayValue}</> })
  };

  const formProps = {
    disabled,
    variant,
    fullWidth: true,
    className: clsx(
      classes.formControl,
      !!errMsg && 'error',
      variant === 'standard' && 'standard',
      label === '' && 'hiddenLabel'
    )
  };

  const renderErrMessage = (
    <FormHelperText component="div">
      <ErrorMessage message={errMsg || ""} />
    </FormHelperText>
  );
  return (
    <FormControl {...formProps} ref={dropdownRef} style={{ opacity: disabled ? '0.5' : '1' }}>
      <InputLabel
        id="demo-simple-select-outlined-label"
        style={{
          width: '80%',
          display: 'inline-block',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}
      >
        {label}
      </InputLabel>
      <Select {...selectProps} data-sl="mask">
        {options.map((option, i: number) => (
          <MenuItem key={i} value={option.value} selected={option.value === value} data-sl="mask">
            <div
              style={{
                width: '80%'
              }}
            >
              {option.label}
            </div>
            <div className="icon">
              <Tick />
            </div>
          </MenuItem>
        ))}
      </Select>
      {!!errMsg && renderErrMessage}
    </FormControl>
  );
};

Dropdown.defaultProps = defaultProps;

export default Dropdown;
