import { IconName } from '@fortawesome/fontawesome-common-types';
import { CSSProperties } from 'react';
import { StyledComponent } from 'styled-components';

export enum IconButtonSize {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl'
}

export enum IconButtonShape {
  Square = 'square',
  Circle = 'circle'
}

export interface IconButtonProps {
  size: IconButtonSize;
  shape: IconButtonShape;
  icon: IconName | React.FC<React.SVGProps<SVGSVGElement>> | StyledComponent<any, any>;
  primary?: boolean;
  plain?: boolean;
  style?: CSSProperties;
  onClick?: (e: any) => void;
  darkMode?: boolean;
  className?: string;
}
