import { colorsUtils } from "@d2c-ui-components-react";
import gtmUtils from "utils/gtm";
declare var window: any;
//this is the chatbot toggle button
export const extraIconClick = () => {
  //this is the chatbot toggle button
  let chatbotToggleButton: any = document.querySelector(".icon-chatbot");
  //OPTIONAL - add a condition to only trigger the click when the chatbot window is not opened
  let chatbotWrapper = document.getElementById("chatbot-wrapper");
  //chatbot window not opened
  if (
    chatbotToggleButton &&
    chatbotWrapper &&
    chatbotWrapper.className.indexOf("closeChatbot") > -1
  ) {
    //trigger click
    gtmUtils.push({
      event: gtmUtils.gaEvent.open_chat,
    });
    chatbotToggleButton.click();
  }
};

const LiveChatModule = (
  $color: string,
  $isDisplay: boolean,
  $methods: any,
  $language: any,
  $loadOnly = false,
  event = {}
) => {
  if (!$color) {
    $color = colorsUtils.fwdOrange;
  }
  if (typeof window !== "undefined") {
    let chatBot: any = document.getElementById("fwd-chatbot");
    const liveChat = document.getElementById("chat-widget-container");
    const chatBotJp = document.getElementsByClassName(
      "chatbot-y6yg88f0-button"
    );
    const chatBotPH: any = document.getElementsByClassName("chatbot-button");
    const chatBotMY: any = document.getElementsByClassName(
      "chatbot-y6yg88f0-bubble"
    );
    if (chatBot) {
      extraIconClick();
      gtmUtils.push({
        event: gtmUtils.gaEvent.open_chat,
      });
      chatBot.style.opacity = 1;
      $methods && $methods.setOnConnected && $methods.setOnConnected();
    } else if (liveChat) {
      gtmUtils.push({
        event: gtmUtils.gaEvent.open_chat,
      });
      window.LiveChatWidget && window.LiveChatWidget.call("maximize");
    } else if (chatBotJp && chatBotJp.length) {
      const chatbotButton: any = document.querySelector(
        ".chatbot-y6yg88f0-button"
      );
      if (chatbotButton) chatbotButton.style.display = "inline-flex";
      window.chatbotToggle && window.chatbotToggle(event);
      window.chatbotToggle &&
        gtmUtils.push({
          event: gtmUtils.gaEvent.close_chat,
        });
    } else if (chatBotPH && chatBotPH.length) {
      chatBotPH[0].style.display = "inline-flex";
      window.chatbotToggle && window.chatbotToggle(event);
      window.chatbotToggle &&
        gtmUtils.push({
          event: gtmUtils.gaEvent.close_chat,
        });
    } else if (chatBotMY && chatBotMY.length) {
      chatBotMY[0].style.display = "inline-flex";
      window.chatbotToggle && window.chatbotToggle(event);
      window.chatbotToggle &&
        gtmUtils.push({
          event: gtmUtils.gaEvent.close_chat,
        });
    } else if (window.$zopim && window.$zopim.livechat) {
      window.$zopim(function () {
        window.$zopim.livechat.hideAll();
        window.$zopim.livechat.departments.filter("");
        window.$zopim.livechat.setOnConnected(function () {
          $methods && $methods.setOnConnected && $methods.setOnConnected();
        });
        // Note: Array.isArray may not work properly for few chinese browsers, see:https://caniuse.com/#search=isArray
        // this condition is added to avoid a run time error on the console.
        $methods &&
          Array.isArray($methods) &&
          $methods.forEach(function (v, k: any) {
            if (k === "setOnConnected") return;
            window.$zopim.livechat[k](v);
          });
      });

      window.$zopim(function () {
        let language = document.body.className
          .toString()
          .replace(/[-\s\w]*lang-([A-Za-z]{2})[-\s\w]*/i, "$1")
          .toLowerCase();
        window.$zopim.livechat.set({
          language,
        });
        let cookieBanner: any =
          document.getElementsByClassName("cookieHeader")[0];
        window.$zopim.livechat.button.setOffsetHorizontal(16);
        window.$zopim.livechat.button.setOffsetHorizontalMobile(16);
        if (cookieBanner) {
          window.$zopim.livechat.button.setOffsetVertical(
            cookieBanner.offsetHeight + 24
          );
          window.$zopim.livechat.button.setOffsetVerticalMobile(
            cookieBanner.offsetHeight + 24
          );
        } else {
          window.$zopim.livechat.button.setOffsetVertical(20);
          window.$zopim.livechat.button.setOffsetVerticalMobile(24);
        }
      });

      window.$zopim(function () {
        if ($language) {
          const { liveChatTitle, offlineChatGreeting, onlineChatGreeting } =
            $language;
          if (liveChatTitle && offlineChatGreeting && onlineChatGreeting) {
            window.$zopim.livechat.setGreetings({
              online: onlineChatGreeting,
              offline: offlineChatGreeting,
            });
            window.$zopim.livechat.window.setTitle(liveChatTitle);
          }
        }
        window.$zopim.livechat.window.setPosition("bl");
        window.$zopim.livechat.button.setPosition("bl");
        window.$zopim.livechat.button.setPositionMobile("bl");
        if ($loadOnly && !$isDisplay) {
          window.$zopim.livechat.hideAll();
        } else if (
          window.DeviceDetection?.isMobile ||
          window.DeviceDetection?.isTablet
        ) {
          window.$zopim.livechat.window.toggle();
        } else {
          if ($isDisplay) window.$zopim.livechat.window.show();
          else window.$zopim.livechat.window.hide();
        }
      });
      window.$zopim.livechat.theme.setColors({
        badge: $color,
        primary: $color,
      });
      window.$zopim.livechat.theme.reload();
    }
  }
};

const LiveChat = () => {
  if (typeof window !== "undefined") {
    window.LiveChat = LiveChatModule;
  }
};
export default LiveChat;
