import { IconName } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React from 'react';
import { Button as BSButton } from 'react-bootstrap';
import '../fontawesome';
import styles from './IconButton.module.scss';
import { IconButtonProps, IconButtonSize } from './IconButton.types';

const svgSize = size => {
  switch (size) {
    case IconButtonSize.ExtraSmall:
      return 16;
    case IconButtonSize.Small:
      return 24;
    case IconButtonSize.Large:
      return 40;
    case IconButtonSize.ExtraLarge:
      return 48;
    case IconButtonSize.Medium:
    default:
      return 32;
  }
};

const IconButton: React.FC<IconButtonProps> = props => {
  const { icon: Icon, size, shape, primary, plain, onClick, darkMode, style } = props;

  const className = clsx({
    [styles.primary]: true,
    [styles.secondary]: !primary,
    [styles[size]]: true,
    [styles[shape]]: true,
    [styles.plain]: plain,
    [styles.darkMode]: darkMode
  });

  const buttonProps = {
    className,
    type: 'button',
    variant: '',
    onClick,
    style
  };

  return (
    <BSButton data-testid="IconButton" {...buttonProps}>
      {typeof Icon === 'string' ? (
        <FontAwesomeIcon className={styles.icon} icon={['fas', Icon as IconName]} fixedWidth />
      ) : (
        <Icon width={svgSize(size)} height={svgSize(size)} />
      )}
    </BSButton>
  );
};

export default IconButton;
