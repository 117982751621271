import { Maybe } from "@types";
import { replaceContentStackURL } from "@utils";
import get from "lodash/get";
import { resolveAbsoluteUrlServer } from "utils/route";
import {
  RecentCardListModernCmsDataProps,
  RecentCardListModernCmsProps,
  RecentCardListModernDataProps,
} from "../dtos/index.type";

export const getRecentCardListModernData = (
  cmsData: RecentCardListModernCmsProps,
  languageCode: string,
  numberOfLang: number
): RecentCardListModernDataProps => {
  const data: Maybe<RecentCardListModernCmsDataProps> =
    cmsData?.reference_5?.[0];

  if (!data) {
    return {
      uid: "",
      displayTitle: "",
      items: [],
      ctaButton: {
        title: "",
        url: "",
        openInNewTab: true,
        gaLabel: "",
      },
    };
  }

  return {
    uid: get(data, "uid", ""),
    displayTitle: get(data, "display_title", ""),
    items: get(data, "items", [])
      .map((item: any) => {
        const body = get(item, "body", null);

        if (!body) {
          return null;
        }

        const url = resolveAbsoluteUrlServer(
          get(item, "global_field.seo.canonical_url", ""),
          languageCode,
          numberOfLang
        );

        const articleBlock = body.find((i: any) => i?.["article_block"])
          ?.article_block?.reference?.[0];

        if (!articleBlock) {
          return null;
        }

        const category = get(
          articleBlock,
          "article_category.[0].title",
          ""
        ) as string;
        const title = get(articleBlock, "title", "") as string;
        const description = get(
          articleBlock,
          "short_description",
          ""
        ) as string;

        const topBanner = body.find((i: any) => i?.["top_banner"])?.top_banner
          ?.reference?.[0];

        const thumbnail = replaceContentStackURL(
          get(topBanner, "banner_desktop.url", "")
        );

        const mobileThumbnail = replaceContentStackURL(
          get(topBanner, "banner_mobile.url", "")
        );

        if (!thumbnail || !title) {
          return null;
        }

        return {
          uid: "",
          url,
          thumbnail,
          mobileThumbnail: mobileThumbnail || thumbnail,
          category,
          title,
          description,
        };
      })
      .filter((item: any) => !!item)
      .slice(0, 4),
    ctaButton: {
      url: resolveAbsoluteUrlServer(
        get(data, "cta_button.url", ""),
        languageCode,
        numberOfLang
      ),
      title: get(data, "cta_button.title", ""),
      openInNewTab: get(data, "cta_button.open_in_new_tab", true),
      gaLabel: get(data, "cta_button.ga_label", ""),
    },
  };
};
