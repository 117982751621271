import get from "lodash/get";
import { resolveAbsoluteUrlServer } from "utils/route";
import {
  OmneHeaderCmsDataProps,
  OmneHeaderDataProps,
  OmneHeaderIconNavigationLinksProps,
  OmneHeaderLogosProps,
  OmneHeaderNavigationSectionsProps,
  OmneHeaderSectionsCmsProps,
  OmneHeaderSocialMediaLinksCmsProps,
  OmneHeaderSubFooterLinksCmsProps,
  OmneHeaderSubLinkCmsProps,
  OmneHeaderNavigationTypeEnum,
} from "../dtos/index.type";

export const getOmneHeaderData = (
  cmsData: OmneHeaderCmsDataProps,
  languageCode: string,
  numberOfLang: number
): OmneHeaderDataProps => {
  if (!cmsData) {
    return {
      uid: "",
      backgroundColor: "",
      socialMediaLinks: [],
      sections: [],
      subFooterLinks: [],
      copyrightText: "",
      logo: "",
      logoUrl: "",
      logoUrlOpenInNewTab: false,
      mobileMenuBackgroundImage: "",
      logos: [],
      navigationSections: [],
      iconNavigationLinks: [],
      mobileIconNavigationLinks: [],
      topMobileIconNavigationLinks: [],
    };
  }

  return {
    uid: get(cmsData, "uid", ""),
    backgroundColor: get(cmsData, "background_color", ""),
    socialMediaLinks: get(cmsData, "social_media_links", []).map(
      (mediaLink: OmneHeaderSocialMediaLinksCmsProps) => ({
        icon: get(mediaLink, "social_media.icon_new", ""),
        link: {
          title: get(mediaLink, "social_media.link.title", ""),
          gaLabel: get(mediaLink, "social_media.link.ga_label", ""),
          href: resolveAbsoluteUrlServer(
            get(mediaLink, "social_media.link.href", ""),
            languageCode,
            numberOfLang
          ),
          openInNewTab: true,
        },
      })
    ),
    sections: get(cmsData, "sections", []).map(
      (section: OmneHeaderSectionsCmsProps) => ({
        majorGroupingLink: {
          type: get(section, "major_grouping.major_grouping_link.href", ""),
          title: get(section, "major_grouping.major_grouping_link.title", ""),
        },
        subLinks: get(section, "major_grouping.sub_links", []).map(
          (subLink: OmneHeaderSubLinkCmsProps) => ({
            link: {
              title: get(subLink, "sub_link.link.title", ""),
              href: resolveAbsoluteUrlServer(
                get(subLink, "sub_link.link.href", ""),
                languageCode,
                numberOfLang
              ),
              gaLabel: get(subLink, "sub_link.link.ga_label", ""),
              openInNewTab: get(subLink, "sub_link.link.open_in_new_tab", true),
            },
          })
        ),
      })
    ),
    subFooterLinks: get(cmsData, "sub_footer_links", []).map(
      (section: OmneHeaderSubFooterLinksCmsProps) => ({
        title: get(section, "link.link.title", ""),
        href: resolveAbsoluteUrlServer(
          get(section, "link.link.href", ""),
          languageCode,
          numberOfLang
        ),
        gaLabel: get(section, "link.link.ga_label", ""),
        openInNewTab: get(section, "link.link.openInNewTab", true),
      })
    ),
    copyrightText: get(cmsData, "copyright_text", ""),
    logo: get(cmsData, "logo", ""),
    logoUrl: resolveAbsoluteUrlServer(
      get(cmsData, "logo_url", ""),
      languageCode,
      numberOfLang
    ),
    mobileMenuBackgroundImage: resolveAbsoluteUrlServer(
      get(cmsData, "mobile_menu_background_image.url", ""),
      languageCode,
      numberOfLang
    ),
    logoUrlOpenInNewTab: get(cmsData, "logo_url_open_in_new_tab", ""),
    logos: get(cmsData, "logos", []).map((logos: OmneHeaderLogosProps) => {
      return {
        icon: get(logos, "logo.logo.url", ""),
        href: resolveAbsoluteUrlServer(
          get(logos, "logo.logo_link", ""),
          languageCode,
          numberOfLang
        ),
        gaLabel: get(logos, "logo.ga_label", ""),
        openInNewTab: get(logos, "logo.open_in_new_tab", true),
      };
    }),
    navigationSections: get(cmsData, "navigation_sections", []).map(
      (section: OmneHeaderNavigationSectionsProps) => {
        return {
          title: get(section, "title_link.link.title", ""),
          href: resolveAbsoluteUrlServer(
            get(section, "title_link.link.href", ""),
            languageCode,
            numberOfLang
          ),
          gaLabel: get(section, "title_link.ga_label", ""),
          openInNewTab: get(section, "title_link.open_link_in_new_tab", true),
        };
      }
    ),
    iconNavigationLinks: get(cmsData, "icon_navigation_links", [])
      .filter((nv: any) => {
        return (
          nv.type != OmneHeaderNavigationTypeEnum.MOBILE &&
          nv.type != OmneHeaderNavigationTypeEnum.TOP_MOBILE
        );
      })
      .map((iconLinks: OmneHeaderIconNavigationLinksProps) => {
        return {
          icon: get(iconLinks, "icon", ""),
          href: resolveAbsoluteUrlServer(
            get(iconLinks, "link", ""),
            languageCode,
            numberOfLang
          ),
          gaLabel: get(iconLinks, "ga_label", ""),
          openInNewTab: get(iconLinks, "open_in_new_tab", true),
        };
      }),
    mobileIconNavigationLinks: get(cmsData, "icon_navigation_links", [])
      .filter((nv: any) => nv.type === OmneHeaderNavigationTypeEnum.MOBILE)
      .map((iconLinks: OmneHeaderIconNavigationLinksProps) => {
        return {
          icon: get(iconLinks, "icon", ""),
          href: resolveAbsoluteUrlServer(
            get(iconLinks, "link", ""),
            languageCode,
            numberOfLang
          ),
          title: get(iconLinks, "title", ""),
          gaLabel: get(iconLinks, "ga_label", ""),
          openInNewTab: get(iconLinks, "open_in_new_tab", true),
        };
      }),
    topMobileIconNavigationLinks: get(cmsData, "icon_navigation_links", [])
      .filter((nv: any) => nv.type === OmneHeaderNavigationTypeEnum.TOP_MOBILE)
      .map((iconLinks: OmneHeaderIconNavigationLinksProps) => {
        return {
          icon: get(iconLinks, "icon", ""),
          href: resolveAbsoluteUrlServer(
            get(iconLinks, "link", ""),
            languageCode,
            numberOfLang
          ),
          title: get(iconLinks, "title", ""),
          gaLabel: get(iconLinks, "ga_label", ""),
          openInNewTab: get(iconLinks, "open_in_new_tab", true),
        };
      }),
  };
};
