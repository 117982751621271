import { breakpoint } from "@d2c-ui-components-react";
import { FC } from "react";
import styled from "styled-components";

const Container = styled.div<{
  backgroundColor?: string;
  textColor?: string;
  overflowHidden: boolean;
}>`
  background: ${(p) => p.backgroundColor || "transparent"};
  ${(p) => (p.textColor ? `color: ${p.textColor};` : "")}

  width: 100%;
  ${(p) => p.overflowHidden && "overflow: hidden;"}
`;

const Wrapper = styled.div<{
  isFeatureProducts?: boolean;
}>`
  padding: 0 1rem;
  margin: 0 auto;

  max-width: 1288px !important;

  ${breakpoint("md")`
    padding: 0 2rem;
  `}

  ${breakpoint("xl")`
    ${(props: any) => {
      if (props.isFeatureProducts) {
        return `
          padding-left: 116px;
          max-width: 1450px !important;
        `;
      }
    }}
  `}
`;

export interface BlockContainerProps {
  className?: string;
  overflowHidden?: boolean;
  isFeatureProducts?: boolean;
  backgroundColor?: string;
  textColor?: string;
  id?: string;
}

export const BlockContainer: FC<BlockContainerProps> = (props) => {
  const {
    children,
    className,
    overflowHidden = false,
    isFeatureProducts,
    backgroundColor,
    textColor,
    id,
  } = props;

  return (
    <Container
      id={id}
      className="block-container-wrapper"
      backgroundColor={backgroundColor}
      textColor={textColor}
      overflowHidden={overflowHidden}
    >
      <Wrapper className={className} isFeatureProducts={isFeatureProducts}>
        {children || <div />}
      </Wrapper>
    </Container>
  );
};
