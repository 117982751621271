import { TaxCalculationResponse } from "../services/index.service";

export type TaxCalculationCmsProps = {
  reference_6: TaxCalculationCmsDataProps[];
};

export type TaxCalculationCmsDataProps = {
  uid: string;
  image_data: {
    title: string;
    image: {
      url: string;
      description: string;
    };
  };
  calculation_data: {
    title: string;
    field: TaxCalculationDataFieldCmsProps[];
    description: string;
    calculate_button_title: string;
    expand_button_title: string;
    result_data: TaxCalculationResultDataCmsProps[];
    table_data: TaxCalculationTableDataCmsProps;
  };
};

export type TaxCalculationDataProps = {
  uid: string;
  imageData: {
    title: string;
    image: {
      url: string;
      description: string;
    };
  };
  calculationData: {
    title: string;
    field: TaxCalculationDataFieldProps[];
    description: string;
    calculateButtonTitle: string;
    expandButtonTitle: string;
    resultData: TaxCalculationResultDataProps[];
    tableData: TaxCalculationTableDataProps;
  };
};

export type TaxCalculationDataFieldCmsProps = {
  key: string;
  type: TaxCalculationFieldType;
  text: string;
  help_text: string;
  mandatory: boolean;
  tab_index: number;
};

export type TaxCalculationDataFieldProps = {
  key: string;
  type: TaxCalculationFieldType;
  text: string;
  helpText: string;
  mandatory: boolean;
  tabIndex: number;
};

export type TaxCalculationResultDataCmsProps = {
  title: string;
  item: TaxCalculationResultDataItemCmsProps[];
};

export type TaxCalculationTableDataCmsProps = {
  title: string;
  chart_title: string;
  item: TaxCalculationTableDataItemProps;
};

export type TaxCalculationResultDataItemCmsProps = {
  highlighted: boolean;
  icon: string;
  text: string;
  currency: string;
};

export type TaxCalculationResultDataProps = {
  title: string;
  item: TaxCalculationResultDataItemProps[];
};

export type TaxCalculationTableDataProps = {
  title: string;
  chartTitle: string;
  item: TaxCalculationTableDataItemProps;
};

export type TaxCalculationTableDataItemProps = {
  title: TaxCalculationTableDataTitleProps[];
  data: TaxCalculationTableDataDataProps[];
  button: TaxCalculationTableDataButtonProps[];
};

export type TaxCalculationResultDataItemProps = {
  highlighted: boolean;
  icon: string;
  text: string;
  currency: string;
};

export type TaxCalculationTableDataTitleProps = {
  text: string;
};

export type TaxCalculationTableDataDataProps = {
  text: string;
};

export type TaxCalculationTableDataButtonCmsProps = {
  text: string;
  url: string;
  open_in_new_tab: boolean;
};

export type TaxCalculationTableDataButtonProps = {
  text: string;
  url: string;
  openInNewTab: boolean;
};

export enum TaxCalculationFieldType {
  NUMBER = "number",
  TEXT = "text",
}

export type TaxCalculationFieldValuesData = {
  key: string;
  value: string | number | undefined;
  isRequired: boolean;
};

export type TaxResultItemProps = {
  firstItem: TaxCalculationResultDataItemProps | null;
  secondItem: TaxCalculationResultDataItemProps | null;
  data: (number | null)[];
};

export type TaxResultTableProps = {
  uid: string;
  tableData: TaxCalculationTableDataProps | null;
  result: TaxCalculationResponse;
  isMobile: boolean;
};
