// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import {
  faEye,
  faEyeSlash,
  faUnlockAlt,
  faEnvelope,
  faSmoking,
  faSmokingBan,
  faUser,
  faUserTie,
  faSlash,
  faChevronLeft,
  faPlus,
  faMinus,
  faSortDown,
  faMale,
  faChild,
  faInfo,
  faInfoCircle,
  faCalculator,
  faCheckCircle,
  faCheck,
  faBirthdayCake,
  faTimes,
  faCaretDown,
  faCircleNotch,
  faCaretRight,
  faCaretUp,
  faPlusCircle,
  faArrowLeft,
  faArrowRight,
  faFileDownload,
  faPlay,
  faExclamationCircle,
  faSort,
  faPhoneAlt,
  faEnvelopeSquare,
  faLock,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';

// Only import required icons to reduce bundle size
library.add(
  faEye,
  faEyeSlash,
  faUnlockAlt,
  faEnvelope,
  faSmoking,
  faSmokingBan,
  faUser,
  faUserTie,
  faSlash,
  faChevronLeft,
  faPlus,
  faMinus,
  faSortDown,
  faMale,
  faChild,
  faInfo,
  faInfoCircle,
  faCalculator,
  faCheckCircle,
  faCheck,
  faBirthdayCake,
  faTimes,
  faCaretDown,
  faCircleNotch,
  faCaretRight,
  faCaretUp,
  faPlusCircle,
  faArrowLeft,
  faArrowRight,
  faFileDownload,
  faPlay,
  faExclamationCircle,
  faSort,
  faPhoneAlt,
  faEnvelopeSquare,
  faLock,
  faChevronDown
);
