import { Maybe } from "@types";

import { getConfiguration } from "page-services/configuration.service";

export const CONTENSTACK_IMAGE_URL = "https://images.contentstack.io";
export const CONTENSTACK_FILE_URL = "https://assets.contentstack.io";

export const replaceContentStackURL = (url?: Maybe<string>): string => {
  if (!url) return "";

  let IMAGE_SOURCE = `${getConfiguration(
    "NEXT_PUBLIC_ROOT_URL",
    process.env.NEXT_PUBLIC_ROOT_URL
  )}/images`;
  let FILE_SOURCE = `${getConfiguration(
    "NEXT_PUBLIC_ROOT_URL",
    process.env.NEXT_PUBLIC_ROOT_URL
  )}/files`;

  if (process.env.NEXT_PUBLIC_IS_EXPORT === "true") {
    IMAGE_SOURCE = process.env.IMAGE_SOURCE || "/images";
    FILE_SOURCE = process.env.FILE_SOURCE || "/files";
  }

  const replacedUrl = url
    .replace(CONTENSTACK_IMAGE_URL, IMAGE_SOURCE)
    .replace(CONTENSTACK_FILE_URL, FILE_SOURCE);

  return replacedUrl;
};
