import { Maybe } from "@types";
import { replaceContentStackURL } from "@utils";
import get from "lodash/get";
import {
  resolveAbsoluteUrlInParagraphServer,
  resolveAbsoluteUrlServer,
} from "utils/route";
import { localeStringToNumber } from "utils/string";
import {
  TaxCalculationCmsDataProps,
  TaxCalculationCmsProps,
  TaxCalculationDataFieldCmsProps,
  TaxCalculationDataProps,
  TaxCalculationFieldType,
  TaxCalculationFieldValuesData,
  TaxCalculationResultDataCmsProps,
  TaxCalculationResultDataItemCmsProps,
  TaxCalculationTableDataDataProps,
  TaxCalculationTableDataTitleProps,
} from "../dtos/index.type";

export const getTaxCalculationData = (
  cmsData: TaxCalculationCmsProps,
  languageCode: string,
  numberOfLang: number
): TaxCalculationDataProps => {
  const data: Maybe<TaxCalculationCmsDataProps> = cmsData?.reference_6?.[0];

  if (!data) {
    return {
      uid: "",
      imageData: {
        title: "",
        image: {
          url: "",
          description: "",
        },
      },
      calculationData: {
        title: "",
        field: [],
        description: "",
        calculateButtonTitle: "",
        expandButtonTitle: "",
        resultData: [],
        tableData: {
          title: "",
          chartTitle: "",
          item: {
            title: [],
            data: [],
            button: [],
          },
        },
      },
    };
  }

  const fields = get(data, "calculation_data.field", []);

  const oddFirstTabIndices: TaxCalculationDataFieldCmsProps[] = [
    ...fields.filter(
      (_: TaxCalculationDataFieldCmsProps, index: number) => index % 2 === 0
    ),
    ...fields.filter(
      (_: TaxCalculationDataFieldCmsProps, index: number) => index % 2 !== 0
    ),
  ];

  oddFirstTabIndices.forEach(
    (item: TaxCalculationDataFieldCmsProps, tabIndex: number) => {
      item.tab_index = tabIndex + 1; // Starting tabIndex from 1
    }
  );

  return {
    uid: get(data, "uid", ""),
    imageData: {
      title: resolveAbsoluteUrlInParagraphServer(
        get(data, "image_data.title", ""),
        languageCode,
        numberOfLang
      ),
      image: {
        url: replaceContentStackURL(get(data, "image_data.image.url", "")),
        description: get(data, "image_data.image.description", ""),
      },
    },
    calculationData: {
      title: get(data, "calculation_data.title", ""),
      field: fields.map((f: TaxCalculationDataFieldCmsProps) => ({
        key: get(f, "key", ""),
        type: get(f, "type", TaxCalculationFieldType.NUMBER),
        text: get(f, "text", ""),
        helpText: get(f, "help_text", ""),
        mandatory: get(f, "mandatory", false),
        tabIndex: f.tab_index,
      })),
      description: resolveAbsoluteUrlInParagraphServer(
        get(data, "calculation_data.description", ""),
        languageCode,
        numberOfLang
      ),
      calculateButtonTitle: get(
        data,
        "calculation_data.calculate_button_title",
        ""
      ),
      expandButtonTitle: get(data, "calculation_data.expand_button_title", ""),
      resultData: get(data, "calculation_data.result_data", []).map(
        (result: TaxCalculationResultDataCmsProps) => ({
          title: get(result, "title", ""),
          item: get(result, "item", []).map(
            (i: TaxCalculationResultDataItemCmsProps) => ({
              highlighted: get(i, "highlighted", false),
              icon: replaceContentStackURL(get(i, "icon", "")),
              text: get(i, "text", ""),
              currency: get(i, "currency", ""),
            })
          ),
        })
      ),
      tableData: {
        title: get(data, "calculation_data.table_data.title", ""),
        chartTitle: get(data, "calculation_data.table_data.chart_title", ""),
        item: {
          title: get(data, "calculation_data.table_data.item.title", []).map(
            (t: TaxCalculationTableDataTitleProps) => ({
              text: resolveAbsoluteUrlInParagraphServer(
                get(t, "text", ""),
                languageCode,
                numberOfLang
              ),
            })
          ),
          data: get(data, "calculation_data.table_data.item.data", []).map(
            (d: TaxCalculationTableDataDataProps) => ({
              text: get(d, "text", ""),
            })
          ),
          button: get(data, "calculation_data.table_data.item.button", []).map(
            (b: TaxCalculationTableDataTitleProps) => ({
              text: get(b, "text", ""),
              url: resolveAbsoluteUrlServer(
                get(b, "url", ""),
                languageCode,
                numberOfLang
              ),
              openInNewTab: get(b, "open_in_new_tab", true),
            })
          ),
        },
      },
    },
  };
};

export const checkValidFieldValues = (
  values: TaxCalculationFieldValuesData[]
): { isValid: boolean; errorIndexes: number[] } => {
  let isValid = true;
  const errorIndexes: number[] = [];

  values.map((data: TaxCalculationFieldValuesData, index: number) => {
    if (data.isRequired && !data.value) {
      isValid = false;

      errorIndexes.push(index);
    }
  });

  return {
    isValid,
    errorIndexes,
  };
};

export const convertValuesToNumber = (
  values: TaxCalculationFieldValuesData[]
): { key: string; value: number }[] => {
  return values.map((data: TaxCalculationFieldValuesData) => ({
    key: data.key,
    value: data.value ? localeStringToNumber(data.value as string) : 0,
  }));
};
