import { Maybe } from "@types";
import { Language } from "i18n/types";
import { GetServerSideProps, NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { setCookie } from "nookies";
import { setConfiguration } from "page-services/configuration.service";
import { getStackData } from "page-services/helper.service";
import { useEffect } from "react";
import { StackData } from "types/Page.interface";
import { generateUniqSerial } from "utils/uid";

import { getInitialLocale } from "../i18n/utils";
import {
  EncryptedGetServerSideProps,
  withEncryptionServerSide,
} from "context/AppEncryptionContext";

interface Props {
  cookieId: string;
  currentLocale: string;
}

const Index: NextPage<Props> = (props: Props) => {
  const { cookieId, currentLocale } = props;
  const router = useRouter();

  setCookie(null, "cookieId", cookieId, {
    maxAge: 30 * 24 * 60 * 60,
    path: "/",
  });

  useEffect(() => {
    router.replace("/[lang]", `/${currentLocale || getInitialLocale()}`);
  });

  return (
    <>
      <Head>
        <meta key="robots" name="robots" content="noindex, nofollow" />
      </Head>
    </>
  );
};

export const getServerSideProps: EncryptedGetServerSideProps<Props> =
  withEncryptionServerSide(async (ctx) => {
    const { query, req } = ctx;

    const market = query.market ? (query.market as string) : undefined;
    const env = query.env ? (query.env as string) : undefined;
    let stackData: Maybe<StackData>;
    let languageCode = getInitialLocale();
    const cookieId: string = req.cookies.cookieId || generateUniqSerial();

    if ((market && env) || cookieId) {
      const status = await setConfiguration(cookieId, market, env);

      stackData = await getStackData();
      languageCode =
        stackData?.defaultLanguage?.languageCode || getInitialLocale();

      if (status !== 200) {
        return {
          redirect: {
            permanent: false,
            destination: `/${languageCode}/${status}`,
          },
        };
      }

      return {
        props: {
          cookieId,
          currentLocale: languageCode,
        },
      };
    }

    return {
      redirect: {
        permanent: false,
        destination: `/${languageCode}/400`,
      },
    };
  });

export default Index;
