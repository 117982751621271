import React from "react";
import styled from "styled-components";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { colorsUtils, breakpoint } from "@d2c-ui-components-react";

export const Heading = styled(Typography)<
  TypographyProps & { component: React.ElementType }
>`
  &.MuiTypography-root {
    font-size: 31px;
    line-height: 39px;
    font-weight: 700;
    display: flex;

    ${breakpoint("lg")`
      font-size: 39px;
      line-height: 49px;
    `}
  }
`;
