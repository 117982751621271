import { breakpoint, useResponsive } from "@d2c-ui-components-react";
import { getWindow, replaceContentStackURL } from "@utils";
import classNames from "classnames";
import { Heading } from "components/Typography";
import I18nContext, { I18nContextData } from "i18n/context/LanguageContext";
import DOMPurify from "isomorphic-dompurify";
import React, { useContext, useEffect, useState } from "react";
import ReactResizeDetector from "react-resize-detector";
import { transformArticlePageCSData, transformArticlePageCSDataServer } from "services/articles.service";
import styled, { css } from "styled-components";
import SwiperCore, { Controller, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  ArticleCardData,
  ArticleCardListColorVariant,
  ArticleCardListSectionData,
  ArticleCardVariant,
} from "types/ArticleCardList.interface";
import colorsUtils from "utils/colors";
import ChevronRight from "../../public/chevron-right.svg";
import { ArticleCard } from "./ArticleCard";
import ArticleCardListV3 from "./ArticleCardListV3";
import ArticleCardV2 from "./ArticleCardV2";
import { ProductAffiliation } from "types/PageType.enum";

const ColorVariantClass = {
  [ArticleCardListColorVariant.GreyBackground]: "grey-variant",
  [ArticleCardListColorVariant.OrangeBackground]: "orange-variant",
  [ArticleCardListColorVariant.Custom]: "custom-variant",
};

function getColorVariantClass(colorVariant: ArticleCardListColorVariant) {
  return ColorVariantClass[colorVariant];
}

const SectionTitle = styled.div`
  margin-bottom: 48px;
`;

const Container = styled.div<{ titleColor: string; bgColor: string }>`
  position: relative;

  &.${getColorVariantClass(ArticleCardListColorVariant.OrangeBackground)} {
    --background-color: ${colorsUtils.fwdOrange};
    --foreground-color: ${colorsUtils.fwdWhite};
    --dot-normal-color: ${colorsUtils.secondaryColorFwdGrey};
    --dot-active-color: ${colorsUtils.fwdWhite};
    --arrow-color: ${colorsUtils.fwdWhite};
    --arrow-hover-background: #dbdfe133;
    --bullet-normal-color: ${colorsUtils.fwdOrange50};
    --bullet-active-color: ${colorsUtils.fwdWhite};
  }

  &.${getColorVariantClass(ArticleCardListColorVariant.GreyBackground)} {
    --background-color: ${colorsUtils.secondaryColorFwdGrey};
    --foreground-color: ${colorsUtils.darkGreen};
    --dot-normal-color: ${colorsUtils.fwdWhite};
    --dot-active-color: ${colorsUtils.fwdOrange};
    --arrow-color: ${colorsUtils.fwdOrange};
    --arrow-hover-background: ${colorsUtils.secondaryFwdOrange};
    --bullet-normal-color: ${colorsUtils.fwdWhite};
    --bullet-active-color: ${colorsUtils.fwdOrange};
  }

  &.${getColorVariantClass(ArticleCardListColorVariant.Custom)} {
    --background-color: ${({ bgColor }) =>
      bgColor || colorsUtils.secondaryColorFwdGrey};
    --foreground-color: ${({ titleColor }) =>
      titleColor || colorsUtils.darkGreen};
    --dot-normal-color: ${colorsUtils.fwdWhite};
    --dot-active-color: ${colorsUtils.fwdOrange};
    --arrow-color: ${({ bgColor }) =>
      bgColor?.toLocaleLowerCase() === colorsUtils.fwdOrange
        ? colorsUtils.fwdWhite
        : colorsUtils.fwdOrange};
    --arrow-hover-background: ${({ bgColor }) =>
      bgColor?.toLocaleLowerCase() === colorsUtils.fwdOrange
        ? "#dbdfe133"
        : colorsUtils.secondaryFwdOrange};
    --bullet-normal-color: ${colorsUtils.fwdWhite};
    --bullet-active-color: ${colorsUtils.fwdOrange};
  }

  background-color: var(--background-color);
  color: var(--foreground-color);
  position: relative;

  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet:hover {
    background: var(--bullet-active-color) !important;
  }

  .swiper-pagination-bullet {
    background: var(--bullet-normal-color);
  }

  .swiper-button-prev,
  .swiper-button-next {
    svg path {
      fill: var(--arrow-color);
    }

    &:hover {
      background-color: var(--arrow-hover-background);
    }
  }

  ${SectionTitle} {
    > div {
      color: var(--foreground-color) !important;
    }
  }

  .MuiSvgIcon-root {
    &:hover {
      background-color: var(--arrow-hover-background) !important;
    }

    path {
      fill: var(--arrow-color);
    }
  }
`;

export interface ArticleCardListProps {
  data: ArticleCardListSectionData;
  className?: string;
}

const MainLayout = styled.div<{ isShowCardItem: boolean }>`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  opacity: ${(props) => (props?.isShowCardItem ? "1" : "0")};

  ${breakpoint("md")`
    overflow: visible;
  `}

  ${breakpoint("lg")`
    max-width: 960px;
  `}

  ${breakpoint("xl")`
    max-width: 1288px;
  `}
`;

const InnerWrapper = styled.div`
  width: 100%;
  padding: 64px 16px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  ${breakpoint("md")`
    padding: 64px 32px;
  `}

  .swiperContainer {
    margin: 0;
    width: 100%;
    overflow: visible !important;
    display: flex;
    flex-direction: column-reverse;

    ${breakpoint("md")`
      overflow: hidden !important;
    `}

    .swiper-pagination {
      position: relative;
      bottom: 0 !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-slide {
      height: auto !important;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    position: absolute;
    width: 32px;
    height: 32px;
    margin-top: 0;
    z-index: 7;
    border-radius: 5px;

    ${breakpoint("md")`
      top: 40%;
    `}

    ${breakpoint("sm")`
      display: flex;
    `}

    ${breakpoint("lg")`
      top: 50%;
      display: flex;
    `}

    &:after {
      display: none;
    }

    &.swiper-button-hidden {
      display: none;
    }
  }

  .swiper-button-prev {
    transform: translateY(-50%) rotateZ(180deg);

    ${breakpoint("sm")`
      left: -13px;
    `}

    ${breakpoint("md")`
      left: -20px;
    `}

    ${breakpoint("lg")`
      left: -40px;
    `}
  }

  .swiper-button-next {
    transform: translateY(-50%);

    ${breakpoint("sm")`
      right: -13px;
    `}

    ${breakpoint("md")`
      right: -20px;
    `}

    ${breakpoint("lg")`
      right: -40px;
    `}
  }
`;

const SliderWrapper = styled.div<{
  currentSlide: number;
  isDesktop: boolean;
  length?: number;
  variant: ArticleCardVariant;
}>`
  margin: 0 -8px;
  position: relative;
  width: auto;

  ${(props) =>
    !props?.isDesktop &&
    props?.currentSlide === 0 &&
    css`
      .swiper-is-mobile.swiper-no-transition .swiper-wrapper {
        transform: translate3d(0, 0, 0) !important;
        transition: all 0.25s !important;
      }
    `}

  ${({ length, isDesktop, variant }) =>
    length &&
    length < 3 &&
    isDesktop &&
    variant !== ArticleCardVariant.Large &&
    css`
      .swiper-wrapper {
        justify-content: center;
      }
    `}

  ${breakpoint("md")`
    margin: 0 -10px;
  `}
`;

const SectionDescription = styled.div`
  margin: 24px 0;
`;

export interface ArticleCardListProps {
  data: ArticleCardListSectionData;
}

SwiperCore.use([Navigation, Pagination, Controller]);

const SlidesPerView = {
  DesktopLarge: 1,
  DesktopMedium: 3,
  DesktopSmall: 3,
  TabletSmall: 2,
  NonDesktop: 1.125,
  NonDesktopSmall: 1,
};

function getSlidesPerView(cardVariant: ArticleCardVariant, isTablet: boolean) {
  if (cardVariant === ArticleCardVariant.Large)
    return SlidesPerView.DesktopLarge;
  else if (cardVariant === ArticleCardVariant.Medium)
    return SlidesPerView.DesktopMedium;
  else if (cardVariant === ArticleCardVariant.Small && isTablet)
    return SlidesPerView.TabletSmall;

  return SlidesPerView.DesktopSmall;
}

export const ArticleCardList: React.FC<ArticleCardListProps> = (props) => {
  const [controlledSwiper, setControlledSwiper] = useState<SwiperCore | null>(
    null
  );
  const [cardHeight, setCardHeight] = useState("100%");
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const isTablet = useResponsive({ breakpoint: "md", offset: -20 });
  const isDesktop = useResponsive({ breakpoint: "lg", offset: 300 });
  const [slidesPerView, setSlidesPerView] = useState(SlidesPerView.NonDesktop);
  const [isShowCardItem, setShowCardItem] = useState(false);

  useEffect(() => {
    if (isTablet && !isDesktop) {
      setSlidesPerView(getSlidesPerView(props?.data.cardVariant, isTablet));
    } else {
      setSlidesPerView(
        props?.data.cardVariant === ArticleCardVariant.Small
          ? SlidesPerView.NonDesktopSmall
          : SlidesPerView.NonDesktop
      );
    }
  }, [isDesktop, isTablet, props?.data.cardVariant]);

  useEffect(() => {
    if (controlledSwiper !== null) {
      controlledSwiper.params.centeredSlides = !isTablet;
      controlledSwiper.params.slidesPerView = slidesPerView;

      controlledSwiper.update();
    }
  }, [controlledSwiper, isTablet, slidesPerView]);

  const onSwiperInit = (ev: SwiperCore) => {
    setCardHeight(`${ev.height}px`);
    setTimeout(() => {
      setShowCardItem(true);
    }, 100);
  };

  return (
    <Container
      className={getColorVariantClass(props?.data.colorVariant)}
      titleColor={props?.data.titleColor}
      bgColor={props?.data.backgroundColor}
    >
      <MainLayout isShowCardItem={isShowCardItem}>
        <InnerWrapper>
          <SectionTitle>
            <Heading component={"h2"} variant={"h5"}>
              {props?.data.title}
            </Heading>
          </SectionTitle>
          {props?.data.description && (
            <SectionDescription>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props?.data.description, {
                    ADD_ATTR: ["target"],
                  }),
                }}
              />
            </SectionDescription>
          )}
          <ReactResizeDetector
            handleHeight
            handleWidth
            onResize={() => {
              if (isTablet) {
                setSlidesPerView(
                  getSlidesPerView(
                    props?.data.cardVariant,
                    isTablet && !isDesktop
                  )
                );
              } else {
                setSlidesPerView(
                  props?.data.cardVariant === ArticleCardVariant.Small
                    ? SlidesPerView.NonDesktopSmall
                    : SlidesPerView.NonDesktop
                );
              }
            }}
            render={() => (
              <SliderWrapper
                currentSlide={currentSlide}
                isDesktop={isTablet}
                length={props?.data?.items?.length}
                variant={props?.data?.cardVariant || ArticleCardVariant.Small}
              >
                <div
                  className={classNames("swiper-button-prev", {
                    "swiper-button-hidden":
                      !isTablet || controlledSwiper?.isBeginning,
                  })}
                  onClick={() => controlledSwiper?.slidePrev()}
                >
                  <ChevronRight />
                </div>
                <div
                  className={classNames("swiper-button-next", {
                    "swiper-button-hidden":
                      !isTablet || controlledSwiper?.isEnd,
                  })}
                  onClick={() => controlledSwiper?.slideNext()}
                >
                  <ChevronRight />
                </div>
                <Swiper
                  navigation={true}
                  className={"swiperContainer"}
                  slidesPerView={slidesPerView}
                  centeredSlides={true}
                  centeredSlidesBounds={true}
                  onSwiper={setControlledSwiper}
                  onAfterInit={onSwiperInit}
                  onSlideChange={(ev) => setCurrentSlide(ev.activeIndex)}
                  controller={{ control: controlledSwiper! }}
                  {...(props?.data?.cardVariant ===
                    ArticleCardVariant.Large && {
                    pagination: {
                      clickable: true,
                    },
                  })}
                >
                  {props?.data?.items?.map((item, index) => {
                    return (
                      <SwiperSlide>
                        {props?.data?.cardVariant ===
                        ArticleCardVariant.Small ? (
                          <ArticleCard
                            key={`${index}-${currentSlide}`}
                            data={item}
                            height={cardHeight}
                            cardVariant={props?.data?.cardVariant}
                            searchTab={props?.data?.searchTab}
                          />
                        ) : (
                          <ArticleCard
                            data={item}
                            height={cardHeight}
                            cardVariant={props?.data?.cardVariant}
                            searchTab={props?.data?.searchTab}
                          />
                        )}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </SliderWrapper>
            )}
          />
        </InnerWrapper>
      </MainLayout>
    </Container>
  );
};

interface CSArticleCardListProps {
  data: {
    reference: {
      autoselect_mode: boolean;
      select_condition: string;
      select_tags: string;
      select_category: string;
      background_color: string;
      foreground_color: string;
      card_variant: string;
      card_button: string;
      design_display: string;
      color_variant: string;
      items: any[];
      title: string;
      subtitle: string;
      article_title: string;
      icon_title: string;
      display_title: string;
      title_color?: string;
      search_tab: any;
      description?: string;
      title_alignment?: "left" | "right" | "center";
      cta_button?: {
        label?: string;
        url?: string;
        icon?: string;
        open_in_new_tab: boolean;
        ga_label?: string;
      };
    }[];
  };
}

function transformCardVariant(cardVariant: string): ArticleCardVariant {
  switch (cardVariant) {
    case "Small":
      return ArticleCardVariant.Small;
    case "Medium":
      return ArticleCardVariant.Medium;
    case "MediumNew":
      return ArticleCardVariant.MediumNew;
    case "Medium four cards":
      return ArticleCardVariant.MediumFourCards;
    case "Four card swipper":
      return ArticleCardVariant.FourCardSwiper;
    default:
      return ArticleCardVariant.Large;
  }
}


export function transformArticleCardListCSDataServer(
  csData: CSArticleCardListProps["data"]["reference"][number],
  languageCode: string,
  numberOfLang: number,
): ArticleCardListSectionData {
  const csCtaButton = csData?.cta_button;

  const ctaButton = {
    label: csCtaButton?.label,
    url: csCtaButton?.url,
    icon: csCtaButton?.icon,
    openInNewTab: !!csCtaButton?.open_in_new_tab,
    gaLabel: csCtaButton?.ga_label,
  };

  return {
    designDisplay: csData?.design_display,
    // TODO: remove hard code
    cardVariant: transformCardVariant(csData?.card_variant),
    title: csData?.display_title || csData?.title || "",
    titleColor: csData?.title_color || "",
    subtitle: csData?.subtitle || "",
    articleTitle: csData?.article_title || "",
    iconTitle: csData?.icon_title || "",
    ctaText: csData?.card_button || "Read more",
    backgroundColor: csData?.background_color || "",
    colorVariant: !csData?.color_variant
      ? ArticleCardListColorVariant.Custom
      : csData?.color_variant === "orange"
      ? ArticleCardListColorVariant.OrangeBackground
      : ArticleCardListColorVariant.GreyBackground,
    items: csData?.items
      ?.map((item) => transformArticlePageCSDataServer(item, languageCode, numberOfLang))
      .filter(
        (item) => item !== null && item !== undefined
      ) as ArticleCardData[],
    searchTab: csData?.search_tab,
    description: csData?.description,
    titleTextAlign: csData?.title_alignment,
    ctaButton,
  };
}

export const CSArticleCardList: React.FC<CSArticleCardListProps> = (props) => {
  const data: any = props?.data?.reference?.[0];
  const i18nContext = useContext(I18nContext);
  if (!data) return <div>Article Card List: Missing Data</div>;
  const categoryAnchor = getWindow()
      ?.location.hash?.replace(/^#/, "")
      ?.replace(/\/$/, "");
  if (categoryAnchor?.toLocaleLowerCase().includes(ProductAffiliation.Takaful)) {
    data.items = data.items?.filter((item: any) => {
      return item.title.includes(ProductAffiliation.TakafulKey);
    })
  } else if (categoryAnchor?.toLocaleLowerCase().includes(ProductAffiliation.Insurance)){
    data.items = data.items?.filter((item: any) => {
      return item.title.includes(ProductAffiliation.InsuranceKey);
    })
  }

  // Add transform props to handle new version
  // const data = transformCSData(csData, i18nContext);


  if (
    [ArticleCardVariant.MediumNew, ArticleCardVariant.FourCardSwiper].includes(
      data.cardVariant
    )
  ) {
    return <ArticleCardListV3 data={data} />;
  }

  if (
    data.designDisplay === "four_card" ||
    data.cardVariant === ArticleCardVariant.MediumFourCards
  ) {
    return (
      <ArticleCardV2
        data={data}
        languageCode={i18nContext.language.languageCode}
      />
    );
  }

  return <ArticleCardList data={data} />;
};
