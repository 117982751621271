import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import React from 'react';
import styled from 'styled-components';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import color from '../colors';
import { CheckBoxProps } from './CheckBox.types';

const StyledFormControl = styled(FormControl)`
  & .MuiFormHelperText-root.Mui-error {
    color: ${color.alertRed};
  }
  &.MuiFormControl-root {
    width: 100%;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${color.secondaryColorFwdGrey};
  margin: 0.5rem 0 1rem 0;
`;

const StyledCheckbox = styled(Checkbox)<{ padding: string }>`
  &.MuiCheckbox-root {
    color: ${color.fwdOrange};
    padding: ${props => props.padding};
    &:hover {
      background-color: transparent;
    }
  }

  &.MuiCheckbox-colorSecondary {
    &:hover {
      background-color: transparent;
    }

    &.Mui-checked {
      color: ${color.fwdOrange};
    }

    &.Mui-disabled {
      color: ${color.fwdOrange};
      filter: opacity(0.7);
    }
  }
`;

const StyledLabel = styled(FormControlLabel)<{
  $alignItems: string;
  $fontSize: string;
  $lineHeight: string;
  $padding: string;
  $background: string;
  $borderRadius: string;
  $innerPadding: string;
}>`
  &.MuiFormControlLabel-root {
    align-items: ${props => props?.$alignItems || 'center'};
    padding: ${props => props?.$padding || '0.75rem 0'};
    margin-right: 0;
  }

  &.MuiTypography-root.MuiFormControlLabel-label {
    font-size: ${props => props?.$fontSize};
    line-height: ${props => props?.$lineHeight};
    color: ${props => props?.color || color.darkGreen};
    border-radius: ${props => props?.$borderRadius || '0'};
    background: ${props => props?.$background || 'transparent'};
    padding: ${props => props?.$innerPadding || '0'};
  }
`;

const CheckBox: React.FC<CheckBoxProps> = props => {
  const { styles, selectedItem, items, onBlur, onSelect, htmlAttributes, errMsg, id, onItemChecked } = props;

  const containerStyle = styles && styles.container ? styles.container : {};
  const labelStyle =
    styles && styles.label
      ? styles.label
      : {
          alignItems: 'flex-start',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          padding: '0',
          borderRadius: '0',
          background: 'transparent',
          innerPadding: '0'
        };

  const handleOnChange = (id, e) => {
    if (onBlur) {
      onBlur(e);
    }

    if (onItemChecked) {
      onItemChecked(e.target.checked, id);
    }

    if (selectedItem.includes(id)) {
      selectedItem.splice(selectedItem.indexOf(id), 1);
      onSelect && onSelect([...selectedItem]);
    } else {
      onSelect && onSelect([...selectedItem, id]);
    }
  };

  const renderItem = items.map((item, i) => {
    const checkBoxProps = {
      checked: selectedItem.includes(item.id),
      value: item.id,
      disabled: item.disabled,
      onChange: e => handleOnChange(item.id, e),
      padding: labelStyle.alignItems === 'flex-start' ? '0px 0.6rem' : '9px',
      inputProps: { ...htmlAttributes },
      onBlur
    };

    const formControlLabelProps = {
      label: item.label,
      control: <StyledCheckbox {...checkBoxProps} />,
      $fontSize: labelStyle?.fontSize || "",
      $lineHeight: labelStyle?.lineHeight || "",
      $alignItems: labelStyle?.alignItems || "",
      $padding: labelStyle?.padding || "",
      $background: labelStyle?.background || "",
      $borderRadius: labelStyle?.borderRadius || "",
      $innerPadding: labelStyle?.innerPadding || ""
    };

    return (
      <>
        <StyledLabel key={item.id} {...formControlLabelProps} />
        {item?.withDivider && <Divider />}
      </>
    );
  });

  const renderErrMessage = (
    <FormHelperText>
      <ErrorMessage message={errMsg || ""} />
    </FormHelperText>
  );

  return (
    <StyledFormControl style={containerStyle} error={!!errMsg} id={id} data-sl="mask">
      <FormGroup>{renderItem}</FormGroup>
      {errMsg && renderErrMessage}
    </StyledFormControl>
  );
};

export default CheckBox;
