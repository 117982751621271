import I18nContext, { I18nContextData } from "i18n/context/LanguageContext";
import { getIsExportEnvironment } from "page-services/helper.service";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { resolveAbsoluteUrl } from "utils/route";
import { useSlugs } from "./route";
import { processUrl } from "./string";

export const useI18nContext = (): I18nContextData => {
  const i18nContext = useContext(I18nContext);

  return i18nContext;
};

export const useFormatCmsUrl = (): ((url?: string) => string) => {
  const i18nContext = useContext(I18nContext);
  const { lang } = useSlugs();

  const isSsg: boolean = useMemo(() => {
    const isExport = getIsExportEnvironment();
    return isExport === "true";
  }, []);

  return useCallback(
    (url) =>
      processUrl(url, (url) =>
        resolveAbsoluteUrl(url, i18nContext, false, isSsg ? "" : lang || "")
      ),
    [i18nContext]
  );
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export function useOrientation() {
  const [orientation, setOrientation] = useState({
    angle: 0,
    type: "landscape-primary",
  });

  useLayoutEffect(() => {
    const handleChange = () => {
      const { angle, type } = window.screen.orientation;
      setOrientation({
        angle,
        type,
      });
    };

    const handle_orientationchange = () => {
      setOrientation({
        type: "UNKNOWN",
        angle: window.orientation,
      });
    };

    if (window.screen?.orientation) {
      handleChange();
      window.screen.orientation.addEventListener("change", handleChange);
    } else {
      handle_orientationchange();
      window.addEventListener("orientationchange", handle_orientationchange);
    }

    return () => {
      if (window.screen?.orientation) {
        window.screen.orientation.removeEventListener("change", handleChange);
      } else {
        window.removeEventListener(
          "orientationchange",
          handle_orientationchange
        );
      }
    };
  }, []);

  return orientation;
}

