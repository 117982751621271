import React from "react";
import { Button as BSButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonProps } from "./Button.types";
import styles from "./Button.module.scss";
import clsx from "clsx";
import Loading from "../../public/loading_btn.gif";
import LoadingOrange from "../../public/loading-orange.gif";

import "../fontawesome";

const defaultProps = {
  primary: true,
  disabled: false,
  darkMode: false,
};

const unHovered = (e) => e.currentTarget.classList.add(styles.unHovered);
const hovered = (e) => e.currentTarget.classList.remove(styles.unHovered);
const loadingStyle = {
  maxHeight: "35px",
  maxWidth: "35px",
  marginTop: "-7px",
};

const Button: React.FC<ButtonProps> = (props) => {
  const {
    children,
    icon: Icon,
    primary,
    custom,
    active,
    disabled,
    darkMode,
    onClick,
    type,
    styles: propStyles,
    svgSize = 18,
    isLoading,
  } = props;

  const className = clsx({
    [styles.primary]: true,
    [styles.secondary]: !primary,
    [styles.disabled]: disabled || isLoading,
    [styles.darkMode]: darkMode,
    [styles.custom]: custom,
    [styles.active]: active,
  });

  const containerStyle =
    propStyles && propStyles.container ? propStyles.container : {};

  const buttonProps = {
    className,
    disabled,
    onClick,
    type: type || "button",
    variant: "",
  };

  const textClassName: string = clsx({ [styles.textWithIcon]: !!Icon }); // icon ? styles.textWithIcon : '';
  const backgroundSize = {
    backgroundSize: "200% 100%",
    maxHeight: "25px",
    paddingLeft: "16px",
    paddingRight: "16px",
  };

  const renderIcon = () => {
    if (!Icon) {
      return;
    }

    if (typeof Icon === "string") {
      return (
        <FontAwesomeIcon
          className={styles.icon}
          icon={["fas", Icon]}
          fixedWidth
        />
      );
    } else {
      return <Icon className={styles.icon} width={svgSize} height={svgSize} />;
    }
  };

  const renderButton =
    typeof children === "string" ? (
      <div
        className={textClassName}
        dangerouslySetInnerHTML={{ __html: children }}
        style={{ whiteSpace: "nowrap" }}
      />
    ) : (
      <div className={textClassName}>{children}</div>
    );

  return (
    <BSButton
      style={{ ...backgroundSize, ...containerStyle }}
      {...buttonProps}
      onMouseEnter={hovered}
      onMouseLeave={unHovered}
      disabled={disabled || isLoading}
    >
      <div className={styles.innerHorizontal}>
        {renderIcon()}
        {isLoading ? (
          <img
            src={primary ? Loading.src : LoadingOrange.src}
            style={loadingStyle}
          />
        ) : (
          renderButton
        )}
      </div>
    </BSButton>
  );
};

Button.defaultProps = defaultProps;

export default Button;
