import { replaceContentStackURL } from "@utils";
import { fwdColors } from "config/fwd-colors";
import { ProductCardProps as ProductCardV2Props } from "shared-components/ProductCardV2/ProductCard";
import { resolveAbsoluteUrlServer } from "utils/route";

export const getProductCardV2Props = (
  product: any,
  { formatCmsUrl, customMapper }
) => {
  const productMetadata = product.body?.find(
    (obj: any) => obj.product_metadata
  )?.product_metadata;

  const topBannerSection = product.body?.find(
    (obj: any) => obj.top_banner
  )?.top_banner;

  const backgroundImageUrl =
    productMetadata?.portrait_image?.url ||
    productMetadata?.image?.url ||
    topBannerSection?.reference?.[0].banner_desktop?.url ||
    "";

  const insuranceCategoryV2 = productMetadata?.insurance_category_v2;

  const discount = productMetadata?.discount || "";
  const discountText = productMetadata?.discount_text || "";
  const channel = productMetadata?.highlight;

  let final = {
    uid: product.uid,
    title: product.global_field?.page_title,
    productTitle: product.title,
    description: productMetadata?.description,
    backgroundImageUrl: replaceContentStackURL(backgroundImageUrl),
    categories: insuranceCategoryV2
      ?.filter((c) => !!c?.title && !c.do_not_show_on_listing_page)
      .map((c) => ({
        iconName: c.icon,
        text: c.title,
      })),
    tagContent: discount + discountText,
    channel: {
      text: channel?.label,
      icon: channel?.icon,
      backgroundColor: channel?.background_colour,
      color: channel?.font_color,
    },
    // TODO: Hard code because don't have any requirement to config this
    ctaButtonIconName: "arrow-right",
    ctaUrl: formatCmsUrl(productMetadata?.related_products?.url),
    ctaOpenInNewTab: productMetadata?.related_products?.open_in_new_tab,
  };

  if (customMapper) {
    return customMapper(product, final);
  }

  return final as ProductCardV2Props;
};

export const getProductListV2Data = (
  products: any[],
  {
    formatCmsUrl,
    customMapper,
  }: {
    formatCmsUrl: any;
    customMapper?: (
      product: any,
      result: ProductCardV2Props
    ) => ProductCardV2Props;
  }
) => {
  return (
    products
      // Remove unpublished product
      ?.filter((product) => !!product?.body)
      .map((product) =>
        getProductCardV2Props(product, { formatCmsUrl, customMapper })
      )
  );
};

export const getProductListV3Data = (
  products: any[],
  {
    formatCmsUrl,
    customMapper,
  }: {
    formatCmsUrl: any;
    customMapper?: (
      product: any,
      result: ProductCardV2Props
    ) => ProductCardV2Props;
  }
) => {
  return getProductListV2Data(products, {
    formatCmsUrl,
    customMapper: (product, result) => {
      const productMetadata = product.body?.find(
        (obj: any) => obj.product_metadata
      )?.product_metadata;

      const ctaButton = productMetadata?.cta_buttons?.[0];
      const discount = productMetadata?.discount || "";

      const final = {
        ...result,
        ctaButtonLabel: ctaButton?.title,
        ctaOpenInNewTab: ctaButton?.open_in_new_tab,
        ctaUrl: formatCmsUrl(ctaButton?.url),
        tagBackgroundColor: discount ? "#6ECEB2" : fwdColors.yellow,
      };

      if (customMapper) {
        return customMapper(product, final);
      }

      return final as ProductCardV2Props;
    },
  });
};

export const getProductCardV2PropsServerSide = (
  product: any,
  locale: string,
  numberOfLang: number
) => {
  const productMetadata = product.body?.find(
    (obj: any) => obj.product_metadata
  )?.product_metadata;

  const topBannerSection = product.body?.find(
    (obj: any) => obj.top_banner
  )?.top_banner;

  const backgroundImageUrl =
    productMetadata?.portrait_image?.url ||
    productMetadata?.image?.url ||
    topBannerSection?.reference?.[0].banner_desktop?.url ||
    "";

  const insuranceCategoryV2 = productMetadata?.insurance_category_v2;

  const discount = productMetadata?.discount || "";
  const discountText = productMetadata?.discount_text || "";
  const channel = productMetadata?.highlight;

  let final = {
    uid: product.uid,
    title: product.global_field?.page_title,
    productTitle: product.title,
    description: productMetadata?.description,
    backgroundImageUrl: replaceContentStackURL(backgroundImageUrl),
    categories: insuranceCategoryV2
      ?.filter((c) => !!c?.title && !c.do_not_show_on_listing_page)
      .map((c) => ({
        iconName: c.icon,
        text: c.title,
      })),
    tagContent: discount + discountText,
    channel: {
      text: channel?.label,
      icon: channel?.icon,
      backgroundColor: channel?.background_colour,
      color: channel?.font_color,
    },
    // TODO: Hard code because don't have any requirement to config this
    ctaButtonIconName: "arrow-right",
    ctaUrl: resolveAbsoluteUrlServer(productMetadata?.related_products?.url, locale, numberOfLang),
    ctaOpenInNewTab: productMetadata?.related_products?.open_in_new_tab,
    showPrice: false,
    price: productMetadata.price,
    priceLabel: "",
  };

  return final as ProductCardV2Props;
};
