import { Maybe } from "@types";
import get from "lodash/get";
import {
  InsuranceInfoCmsDataProps,
  InsuranceInfoCmsProps,
  InsuranceInfoDataProps,
  InsuranceInfoItemCmsDataProps,
  ItemDataCmsProps,
} from "../dtos/index.type";

export const getInsuranceInfoData = (
  cmsData: InsuranceInfoCmsProps
): InsuranceInfoDataProps => {
  const data: Maybe<InsuranceInfoCmsDataProps> = cmsData?.reference_5?.[0];

  if (!data) {
    return {
      uid: "",
      displayTitle: "",
      backgroundColor: "",
      iconTitle: "",
      item: [],
    };
  }

  return {
    uid: get(data, "uid", ""),
    displayTitle: get(data, "display_title", ""),
    backgroundColor: get(data, "background_colour", ""),
    iconTitle: get(data, "icon_title", ""),
    item: get(data, "item", []).map((item: InsuranceInfoItemCmsDataProps) => ({
      data: get(item, "data", []).map((data: ItemDataCmsProps) => ({
        icon: get(data, "icon", ""),
        title: get(data, "title", ""),
        description: get(data, "description", ""),
      })),
      backgroundLabel: get(item, "background_label", ""),
      label: get(item, "label", ""),
      content: get(item, "content", ""),
    })),
  };
};
