import React, { FC } from "react";
import { replaceContentStackURL } from "@utils";
import NextImage from "next/future/image";
import type { ImageProps as NextImageProps } from "next/future/image";

export interface ImageProps {
  url?: string;
  width?: string;
  height?: string;
  alt?: string;
  className?: string;
  srcSet?: string;
  layout?: string;
}

export const Image: FC<ImageProps & Omit<NextImageProps, "src" | "alt">> = (
  props
) => {
  const {
    url = "",
    width = "auto",
    height = "auto",
    className = "",
    alt = "",
    ...otherProps
  } = props;

  return (
    <NextImage
      src={replaceContentStackURL(url)}
      loading="lazy"
      srcSet={props?.srcSet}
      layout={props?.layout}
      width={0}
      height={0}
      style={{ width: `${width}`, height: `${height}` }}
      className={className}
      alt={alt}
      {...otherProps}
    />
  );
};
