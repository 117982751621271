import styled from 'styled-components';
import { breakpoint } from '../responsive';
import { DynamicDivProps } from './DynamicDiv.types';

const breakpointUtils = bp =>
  breakpoint(bp)`
    ${(props: DynamicDivProps) => {
      let styles = '';

      if (props[`${bp}Margin`]) {
        styles += `margin: ${props[`${bp}Margin`]} !important;`;
      }

      if (props[`${bp}Padding`]) {
        styles += `padding: ${props[`${bp}Padding`]} !important;`;
      }

      if (props[`${bp}MaxWidth`]) {
        styles += `max-width: ${props[`${bp}MaxWidth`]} !important;`;
      }

      return styles;
    }}
  `;

const DynamicDiv = styled.div.attrs(props => ({
  className: props.className
}))<DynamicDivProps>`
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'transparent')} !important;
  border: ${props => (props.border ? props.border : 'none')} !important;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0')} !important;
  display: ${props => (props.display ? props.display : 'block')};
  grid-area: ${props => (props.gridArea ? props.gridArea : 'initial')};
  ${props => props.justifyContent && `justify-content: ${props.justifyContent} !important;`}
  ${props => props.alignItems && `align-items: ${props.alignItems} !important;`}
  ${props => props.height && `height: ${props.height} !important;`}

  margin: ${props => (props.xsMargin ? props.xsMargin : '0 0 0 0')} !important;
  padding: ${props => (props.xsPadding ? props.xsPadding : '0 0 0 0')} !important;

  max-width: ${props => (props.xsMaxWidth ? props.xsMaxWidth : '100%')} !important;
  ${props => props.width && `width: ${props.width} !important;`}

  ${['sm', 'md', 'lg', 'xl'].map(o => breakpointUtils(o))}
`;

export default DynamicDiv;
