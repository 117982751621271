export function applyWhenHoverSupported(css: String) {
  return `
    @media(hover: hover) and (pointer: fine) {
      ${css}
    }
  `;
}

export function combineClassName(...args) {
  return args.filter((x) => !!x).join(" ");
}
