import set from "lodash/set";

const DEFAULT_FONT_FAMILY = "FWDCircularWeb, Noto Sans Thai, sans-serif";
type HTMLStyleElementProps = HTMLStyleElement & {
  styleSheet?: any;
};

const SPACING_MAPPING: { [space: string]: string } = {
  large: "96px",
  medium: "64px",
  none: "0",
};

export function buildFontFamilyStyle(
  fontFamily: string = DEFAULT_FONT_FAMILY,
  fontFace: string = ""
): string {
  return `
    html *:not(.material-icons):not([class^="fib-"]):not([class^="fas"]) { font-family: ${fontFamily} }
    .formPageRender input[type=email], .formPageRender input[type=password], .formPageRender input[type=text] { font-family: ${fontFamily} }
    ${fontFace}
  `;
}

const moveElementToAnotherWrapper = ({
  elementSelector,
  targetElementSelector,
}) => {
  const elementContent = document.querySelectorAll(`${elementSelector}`);
  if (elementContent.length) {
    const elementContentNode = elementContent[0];
    set(elementContentNode, "style.position", "static");
    set(elementContentNode, "style.left", "auto");
    set(elementContentNode, "style.bottom", "auto");
    elementContentNode.classList.add("moved-element");

    const fragment = document.createDocumentFragment();
    fragment.appendChild(elementContentNode);

    const wrapperElements = document.querySelectorAll(targetElementSelector);
    if (wrapperElements && wrapperElements.length) {
      for (
        let elementIndex = 0;
        elementIndex < wrapperElements.length;
        elementIndex++
      ) {
        const element = wrapperElements[elementIndex];
        if (element) {
          element.innerHTML = "";
          element.appendChild(fragment);
        }
      }
    }
  }
};

export { SPACING_MAPPING, moveElementToAnotherWrapper };
