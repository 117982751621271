import { Maybe } from "@types";
import { replaceContentStackURL } from "@utils";
import get from "lodash/get";
import { resolveAbsoluteUrlInParagraphServer } from "utils/route";
import {
  FaqData,
  InfographicFaqAlignment,
  InfographicFaqCmsDataProps,
  InfographicFaqCmsProps,
  InfographicFaqDataProps,
  UTMFaqCmsData,
} from "../dtos/index.type";

export const getInfographicFaqData = (
  cmsData: InfographicFaqCmsProps,
  languageCode: string,
  numberOfLang: number
): InfographicFaqDataProps => {
  const data: Maybe<InfographicFaqCmsDataProps> = cmsData?.reference_6?.[0];

  if (!data) {
    return {
      uid: "",
      displayTitle: "",
      subtitle: "",
      infographicFile: "",
      infographicAlt: "",
      alignment: InfographicFaqAlignment.RIGHT,
      faq: [],
      utmContent: [],
      faq_schema: [],
    };
  }

  return {
    uid: get(data, "uid", ""),
    displayTitle: get(data, "display_title", ""),
    subtitle: get(data, "subtitle", ""),
    infographicFile: replaceContentStackURL(
      get(data, "infographic_file.url", "")
    ),
    infographicAlt: replaceContentStackURL(
      get(data, "infographic_file.description", "")
    ),

    alignment: get(data, "alignment", InfographicFaqAlignment.RIGHT),
    faq: get(data, "faq", []).map((item: FaqData) => ({
      question: resolveAbsoluteUrlInParagraphServer(
        get(item, "question", ""),
        languageCode,
        numberOfLang
      ),
      answer: resolveAbsoluteUrlInParagraphServer(
        get(item, "answer", ""),
        languageCode,
        numberOfLang
      ),
    })),
    utmContent: get(data, "utm_content", []).map((item: UTMFaqCmsData) => ({
      utm: get(item, "utm", ""),
      displayTitle: get(item, "display_title", ""),
      subtitle: get(item, "subtitle", ""),
      infographicFile: replaceContentStackURL(
        get(item, "infographic_file.url", "")
      ),
      infographicAlt: replaceContentStackURL(
        get(item, "infographic_file.description", "")
      ),

      faq: get(item, "faq", []).map((i: FaqData) => ({
        question: resolveAbsoluteUrlInParagraphServer(
          get(i, "question", ""),
          languageCode,
          numberOfLang
        ),
        answer: resolveAbsoluteUrlInParagraphServer(
          get(i, "answer", ""),
          languageCode,
          numberOfLang
        ),
      })),
    })),

    faq_schema: [],
  };
};
