import MuiPopper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PopperProps } from './Popper.types';

const useStyles = makeStyles(theme => ({
  popper: {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.24)',
    borderRadius: '4px',
    zIndex: 9999,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    }
  },
  arrow: {
    zIndex: 9999,
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  }
}));

const Arrow = styled.span<{ $arrowLeftOffset: number }>`
  left: ${(props: any) => props.$arrowLeftOffset || 0}px!important;
`;

const Popper: React.FC<PopperProps> = ({ children, open, anchorEl, arrowLeftOffset, offset }) => {
  const [arrowRef, setArrowRef] = useState(null);
  const classes = useStyles();

  return (
    <MuiPopper
      placement="bottom"
      className={classes.popper}
      open={open}
      anchorEl={anchorEl}
      modifiers={{
        arrow: {
          enabled: true,
          element: arrowRef
        },
        offset: {
          enabled: true,
          offset: offset || '0 0'
        }
      }}
    >
      {(arrowLeftOffset && arrowLeftOffset > 0) ? (
        <Arrow $arrowLeftOffset={arrowLeftOffset} className={classes.arrow} ref={setArrowRef as any}></Arrow>
      ) : (
        <span className={classes.arrow} ref={setArrowRef as any}></span>
      )}

      {children}
    </MuiPopper>
  );
};

export default Popper;
