import { getUtmParams, setUtmParams } from "./utm";

export { default as gtmUltils } from "./gtm";
export * from "./relativePaths";

export const getWindow = () => (typeof window === "undefined" ? null : window);
export const getWindowOrigin = () => getWindow()?.location?.origin || "";
export const getWindowHref = () => getWindow()?.location?.href || "";
export const setWindowHref = (href) => {
  if (typeof window !== "undefined") {
    //window.location.href = href;
    window.open(href, "_self");
  }
};
export const setWindowOpen = (href, target, features?) => {
  if (typeof window !== "undefined") {
    window.open(href, target, features);
  }
};

if (typeof window !== "undefined") {
  //@ts-ignore
  window.open = (function (open) {
    return function (url: string, target, features) {
      const utm = getUtmParams();

      const newHref: string = setUtmParams(utm, url);

      return open(newHref, target, features);
    };
  })(window.open);
}
