import moment from "moment";

const BUDDHIST_YEAR_OFFSET = 543;

export const TH_DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

export const getBuddhistDate = (date: string, outputFormat: string, inputFormat?: string) => {
  const christianYear = inputFormat ? moment(date, inputFormat).format("YYYY") : moment(date).format("YYYY");
  const buddhishYear = (parseInt(christianYear) + BUDDHIST_YEAR_OFFSET).toString();

  return (inputFormat ? moment(date, inputFormat) : moment(date))
    .format(
      outputFormat
        .replace("YYYY", buddhishYear)
        .replace("YY", buddhishYear.substring(2, 4))
    )
    .replace(christianYear, buddhishYear);
};

export const getCurrentYear = (languageCode: string) => {
  if (languageCode === "th") {
    return new Date().getFullYear() + BUDDHIST_YEAR_OFFSET;
  }
  return new Date().getFullYear();
};

export const getActualYear = (languageCode: string, year: number) => {
  if (languageCode === "th") {
    return year - BUDDHIST_YEAR_OFFSET;
  }
  return year;
}