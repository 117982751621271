import { I18nContextData } from "i18n/context/LanguageContext";
import find from "lodash/find";
import get from "lodash/get";

export function formatImageToPicture(
  imageUrl: string,
  mobileImageUrl?: string
) {
  const imageObj = {
    src: imageUrl,
    x1: imageUrl,
    x2: imageUrl
  };

  const mobileImageObj = !mobileImageUrl
    ? imageObj
    : {
        src: mobileImageUrl,
        x1: mobileImageUrl,
        x2: mobileImageUrl
      };

  // { src: string, x1: string, x2: string}
  return {
    media: {
      image: {
        value: imageObj
      },
      mobileImage: {
        value: mobileImageObj
      }
    }
  };
}

export function formatCurrency(
  value: string,
  i18nContext: I18nContextData,
  decimalNumber = 2
) {
  if (!["hk", "mo"].includes((i18nContext.countryCode || "").toLowerCase()))
    return value;
  const price = parseFloat(value).toFixed(decimalNumber);
  if (["zh", "tc"].includes(i18nContext.language.languageCode)) {
    return `美元${price}`;
  } else {
    return `US$${price}`;
  }
}

export const getCurrencyCode = (currency, locale = "en-MY") => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency
  });
  const formatParts = formatter?.formatToParts(1);

  return formatParts
    ? get(
        find(formatParts, (partItem) => partItem.type === "currency"),
        "value",
        ""
      )
    : "";
};
