import color from '../colors';

export const generateStyles = (style, optionWidth) => theme => ({
  formControl: {
    '&.MuiFormControl-root.hiddenLabel': {
      '& fieldset legend span': {
        display: 'none'
      }
    },
    '&.MuiFormControl-root.standard': {
      '& svg.MuiSelect-icon': {
        marginRight: '0rem'
      },
      '& svg.MuiSelect-icon.Mui-disabled path': {
        fill: 'rgba(0, 0, 0, 0.38)'
      },
      '& .MuiInputBase-root': {
        marginTop: '0rem',
        '& .MuiSelect-root': {
          fontSize: '0.8rem'
        }
      },
      '& .MuiInputBase-root:before': {
        borderBottom: 'none' // hidden border line in filled
      },
      '& label': {
        display: 'none'
      },
      '& .MuiInput-underline:after': {
        display: 'none'
      }
    },

    '&.MuiFormControl-root': {
      '& svg.MuiSelect-icon': {
        marginRight: style?.iconRightMargin || '1rem'
      },
      '& svg.MuiSelect-icon.Mui-disabled path': {
        fill: 'rgba(0, 0, 0, 0.38)'
      },
      '&.error': {
        '& .MuiInputBase-root svg.MuiSelect-icon path': {
          fill: color.alertRed
        },
        '& label': {
          color: color.alertRed,
          '&.MuiInputLabel-shrink': {
            color: color.alertRed
          },
          '&.Mui-disabled.MuiInputLabel-shrink': {
            color: color.alertRed
          },
          '&.Mui-focused': {
            color: color.alertRed
          }
        },
        '& .MuiInputBase-root': {
          '& fieldset': {
            borderColor: color.alertRed
          },
          '&:hover fieldset': {
            borderColor: color.alertRed
          }
        }
      },
      '& label': {
        color: color.grey3,
        fontWeight: '300',
        '&.MuiInputLabel-shrink': {
          color: color.darkGreen
        },
        '&.Mui-disabled.MuiInputLabel-shrink': {
          color: color.darkGreen,
          opacity: '0.5'
        },
        '&.Mui-focused': {
          color: color.fwdOrange
        }
      },
      '& .MuiInputBase-root': {
        '& fieldset': {
          borderColor: color.grey
        },
        '&:hover fieldset': {
          borderColor: color.grey
        },
        '&.Mui-disabled': {
          '& fieldset': {
            borderColor: color.grey
          },
          '&:hover fieldset': {
            borderColor: color.grey
          }
        },
        '&.Mui-focused': {
          '& fieldset': {
            borderWidth: '1px',
            borderColor: color.fwdOrange,
            caretColor: color.fwdOrange
          },
          '& input': {
            caretColor: color.fwdOrange
          }
        }
      },
      '& .MuiSelect-root': {
        backgroundColor: '#fff',
        '& .icon': {
          display: 'none'
        },
        '&.MuiOutlinedInput-input': {
          fontWeight: '300',
          padding: `${style?.paddingY || '18.5px'} 14px`,

          // set ... if text is too long to display
          '& div': {
            maxWidth: 'calc(95% - 14px)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }
        }
      },
      '& .MuiFormHelperText-root': {
        display: 'flex',
        alignItems: 'center',
        color: color.alertRed,
        margin: '1px',
        '& icon': {
          fontSize: '0.8rem'
        },
        '& errMsg': {
          fontSize: '0.75rem'
        }
      },
      '& .MuiSelect-nativeInput': {
        bottom: 'initial',
        top: 0
      }
    }
  },
  dropdownShadow: {
    boxShadow: '0 0 8px 0 rgba(0,0,0,0.24)',
    '& .MuiMenu-list': {
      '& .Mui-selected': {
        backgroundColor: 'rgba(0, 0, 0, 0)'
      }
    }
  },
  scrollBar: {
    maxHeight: '240px', // fixed by design
    '&::-webkit-scrollbar': {
      // General scrollbar
      width: '6px'
    },
    '&::-webkit-scrollbar-button': {
      // Side buttons
      width: '0px',
      height: '0px'
    },
    '&::-webkit-scrollbar-thumb': {
      // Scrollbar slider
      background: color.grey,
      borderRadius: '2px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      // Slider hover
      background: '#777'
    },
    '&::-webkit-scrollbar-thumb:active': {
      // Slider active
      background: '#555'
    },
    '&::-webkit-scrollbar-track': {
      // Scrollbar track
      background: '#fff',
      borderRadius: '2px'
    },
    '&::-webkit-scrollbar-track:hover': {
      // Track hover
      background: '#ccc'
    },
    '&::-webkit-scrollbar-track:active': {
      // Track active
      background: '#ccc'
    },
    '&::-webkit-scrollbar-corner': {
      // Scrollbar corners where scrollbars meet
      background: 'transparent'
    }
  },
  menuItems: {
    '&.standard': {
      '& .MuiMenuItem-root': {
        paddingRight: '1rem',
        width: 'unset',
        '& .icon': {
          marginLeft: '1rem'
        }
      }
    },
    '& .MuiList-root': {
      width: '100% !important',
      paddingRight: '0 !important'
    },
    '& .MuiMenuItem-root': {
      whiteSpace: 'unset',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      fontSize: '1rem',
      fontWeight: '300',
      lineHeight: '24px',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'space-between',
      width: `${optionWidth}px`,
      alignItems: 'flex-start',

      '& .icon': {
        display: 'none'
      },
      '&.Mui-selected': {
        // backgroundColor: color.fwdOrange,
        // color: '#fff',
        '& .icon': {
          display: `${style?.hideSelectedIcon ? 'none' : 'block'}`
        }
      },
      '&:hover, &.Mui-selected:hover': {
        backgroundColor: color.fwdOrange,
        color: '#fff',
        '& svg': {
          fill: '#fff'
        }
      }
    }
  }
});
