import { Maybe } from "@types";
import { replaceContentStackURL } from "@utils";
import get from "lodash/get";
import { resolveAbsoluteUrlInParagraphServer } from "utils/route";
import {
  GridBlockCmsDataProps,
  GridBlockCmsProps,
  GridBlockDataProps,
} from "../dtos/index.type";

export const getGridBlockData = (
  cmsData: GridBlockCmsProps,
  languageCode: string,
  numberOfLang: number
): GridBlockDataProps => {
  const data: Maybe<GridBlockCmsDataProps> = cmsData?.reference_5?.[0];

  if (!data) {
    return {
      uid: "",
      displayTitle: "",
      backgroundColor: "",
      column: 0,
      items: [],
    };
  }

  return {
    uid: get(data, "uid", ""),
    displayTitle: get(data, "display_title", ""),
    backgroundColor: get(data, "background_colour", ""),
    column: get(data, "column", 3),
    items: get(data, "item", []).map((item: any) => ({
      image: {
        url: replaceContentStackURL(get(item, "image.url", "")),
      },
      title: resolveAbsoluteUrlInParagraphServer(
        get(item, "title", ""),
        languageCode,
        numberOfLang
      ),
      subtitle: resolveAbsoluteUrlInParagraphServer(
        get(item, "subtitle", ""),
        languageCode,
        numberOfLang
      ),
      description: resolveAbsoluteUrlInParagraphServer(
        get(item, "description", ""),
        languageCode,
        numberOfLang
      ),
    })),
  };
};
