import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as BSButton } from 'react-bootstrap';
import styled from 'styled-components';
import '../fontawesome';
import colors from '../colors';

interface StyledProps {
  $primary: boolean;
  $darkMode: boolean;
  $large: boolean;
}

export const StyledButton = styled(BSButton)<StyledProps>`
  color: black;
  font-size: 14px; // Fixed by design
  line-height: 18px; // Fixed by design
  font-weight: bold;
  font-style: normal;
  align-items: baseline;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 4px;

  &:focus {
    text-decoration: none;
    box-shadow: none;
  }

  &:hover {
    color: black;
    background-color: rgba(232, 119, 34, 0.2);
    border-radius: 4px;
    text-decoration: none;
  }

  ${props =>
    !props.$primary &&
    `
    color: ${colors.fwdOrange};
    &:hover {
      color: ${colors.fwdOrange};
    }
  `}

  ${props =>
    props.$darkMode &&
    `
    color: white;
    &:hover {
      color: white;
      background-color: rgba(255, 255, 255, 0.2);
    }
    & ${StyledIcon} {
      color: white;
    }
  `}

  ${props => props.$large && `font-size: 16px;`}

  .textWithIcon {
    text-align: left;
    align-self: center;
  }

  svg {
    color: ${colors.fwdOrange};
    font-weight: normal;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  color: ${colors.fwdOrange};
  font-weight: normal;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
`;
