export type Locale = "th" | "en";

export const PrefixUrlLanguages: string[] = [];
for (const lang of ['th', 'en', 'ja', 'jp', 'vi', 'zh', 'id', 'km', 'tc']) {
  PrefixUrlLanguages.push(`/${lang}/`);
  PrefixUrlLanguages.push(`${lang}/`);
}

export interface Language {
  locale: string;
  languageCode: string;
  displayName: string;
}

export type LanguageCode = string;
