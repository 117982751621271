import { CmsCta } from "@types";

interface CMSTabBodyText {
  body_text: {
    text: string;
  };
}

interface CMSTabBodyHeading {
  heading_text: {
    text: string;
  };
}

interface CMSTabBodyTips {
  tips: {
    items: {
      icon: string;
      text: string;
    }[];
  };
}

type CMSTabBodyButton = {
  button: CmsCta;
};

type CMSTabBodyBlock =
  | CMSTabBodyText
  | CMSTabBodyHeading
  | CMSTabBodyTips
  | CMSTabBodyButton;

export function isTabBodyText(
  tabBody: CMSTabBodyBlock
): tabBody is CMSTabBodyText {
  return (tabBody as CMSTabBodyText).body_text !== undefined;
}

export function isTabBodyHeading(
  tabBody: CMSTabBodyBlock
): tabBody is CMSTabBodyHeading {
  return (tabBody as CMSTabBodyHeading).heading_text !== undefined;
}

export function isTabBodyTips(
  tabBody: CMSTabBodyBlock
): tabBody is CMSTabBodyTips {
  return (tabBody as CMSTabBodyTips).tips !== undefined;
}

export function isTabBodyButton(
  tabBody: CMSTabBodyBlock
): tabBody is CMSTabBodyButton {
  return (tabBody as CMSTabBodyButton).button !== undefined;
}

export interface CMSTabbedContentSection {
  reference_6: [
    {
      title: string;
      display_title: string;
      tabs: {
        tab: {
          _metadata: {
            uid: string;
          };
          title: string;
          desktop_image: {
            url: string;
            description?: string;
          };
          mobile_image: {
            url: string;
            description?: string;
          };
          body: CMSTabBodyBlock[];
        };
      }[];
    }
  ];
}

interface TabBodyText {
  text: string;
  type: "TabBodyText";
}

interface TabBodyHeading {
  headingText: string;
  type: "TabBodyHeading";
}

interface TabBodyTips {
  items: {
    icon: string;
    text: string;
  }[];
  type: "TabBodyTips";
}

interface TabBodyButton {
  title: string;
  openInNewTab: boolean;
  icon: string;
  gaLabel: string;
  url: string;
  type: "TabBodyButton";
}

export type TabbedContentSectionBodyBlock =
  | TabBodyText
  | TabBodyHeading
  | TabBodyTips
  | TabBodyButton;

export interface TabbedContentSectionData {
  title: string;
  tabs: {
    title: string;
    uid: string;
    desktopImage: {
      url: string;
      description?: string;
    };
    mobileImage: {
      url: string;
      description?: string;
    };
    body: TabbedContentSectionBodyBlock[];
  }[];
}
