import { ArticleCardListCarousel } from "@fwd-dep/nextgen-ui-lib";
import { gtmUltils } from "@utils";
import { useMemo } from "react";
import { ArticleCardListSectionData, ArticleCardListSectionDataV2 } from "types/ArticleCardList.interface";
import { formatImageToPicture } from "utils/format";

function tranformData(data: ArticleCardListSectionData): ArticleCardListSectionDataV2 {

    return {
        ...data,
        items: data.items.map((item) => ({
            ...item,
            picture: formatImageToPicture(item.thumbnail?.url || item.desktopImage.url)
        }))
    }
}

export default function ArticleCardV2({ data, languageCode }: { data: ArticleCardListSectionData, languageCode: string }) {

    const compData = useMemo(() => tranformData(data), [data]);

    return (
        <ArticleCardListCarousel
            {...compData}
            gtmPushEvent={(item) => {
                gtmUltils.pushEvent({
                    event: 'navigation',
                    event_action: 'button_click',
                    event_label: item.titleGALabel || item.title,
                    language: languageCode,
                    componentName: 'article_card_list_carousel'
                });
            }}
        />);
}